import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/auth";

export default function AuthGuard({ children }) {
  const auth = useContext(AuthContext);
  const { userLoggedIn } = auth;
  if (!userLoggedIn) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}
