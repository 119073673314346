import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
// import TopBar from "./TopBar";
import { styled } from "@mui/material/styles";
import ThemeContext from "../../context/themeContext";
import Footer from "./Footer";
import TopBar from "../DashboardLayout/TopBar";
import { useLocation } from "react-router";
import MaintenanceMode from "../../components/MaintenanceMode";
import SupportButton from "../../components/SupportButton";
import FormCard from "../../components/FormCard";
import TopBarNew from "./TopBarNew";
import LoginRegisterModal from "../../views/pages/auth/LoginRegisterModal";

const LayoutContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  height: "100%",
  width: "100%",
  position: "relative",
}));

/*
This component is HOC to unify the header and footer throughout the application for home layout
*/
export default function HomeLayout({ children }) {
  const location = useLocation();
  const themeSettings = useContext(ThemeContext);
  const [loginModal, setLoginModal] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <LayoutContainer>
      <TopBarNew
        themeSettings={themeSettings}
        isDashboard={false}
        open={loginModal}
        setOpen={setLoginModal}
      />
      {/* <TopBar themeSettings={themeSettings} /> */}

      <Box>
        {/* <SupportButton /> */}
        {/* <Box sx={{ mt: { xs: 12, sm: 10, lg: 4 } }}>{children}</Box> */}
        <Box sx={{ mt: { xs: 0, sm: 10, lg: 4 } }}>{children}</Box>
        <Footer themeSettings={themeSettings} />
      </Box>

      <MaintenanceMode />
      <LoginRegisterModal open={loginModal} setOpen={setLoginModal} />
    </LayoutContainer>
  );
}

HomeLayout.propTypes = {
  children: PropTypes.node,
};
