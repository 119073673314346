import React from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import Logo from "./Logo";

const RootBox = styled(Box)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.primary.main,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  left: 0,
  position: "fixed",
  top: 0,
  width: "100%",
  zIndex: 2000,
  padding: "0",
}));

export default function PageLoader() {
  return (
    <RootBox>
      <Box align="center">
        <Box
          component={"img"}
          src="/assets/icons/logo.gif"
          // height={"50px"}
          width={200}
        />
      </Box>
    </RootBox>
  );
}
