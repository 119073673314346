// export const colors = {
//   darkPrimary: "#1D1D1D",
//   darkSecondary: "#2C2C2C",
//   bgDark: "#272727",
//   white: "#fff",
//   black: "#000",
//   darkGrey: "#404040",
//   gold: "#ffc107",
//   btnGold: "#FFCA65",
//   red: "#FB3E3E",
//   green: "#01bc8d",
// };

// export const colors = {
//   darkPrimary: "#ffffff",
//   darkSecondary: "#408effba",
//   bgDark: "#272727",
//   white: "#ffffff",
//   black: "#000",
//   darkGrey: "#404040",
//   // gold: "#ffc107",
//   gold: "#408effba",
//   btnGold: "#E8751A",
//   red: "#FB3E3E",
//   green: "#01bc8d",
//   cardBackground: "#408effba",
//   cardBackgroundLight: "#4090ff74",
//   primaryWhite: "#ffffff",
//   buttonBg: "#d6d2fb",
//   blueText: "#2e368f",
//   lightGray: "#c4c4c4",
//   btnBlue: "#432ae1",
// };

export const colors = {
  darkPrimary: "#44475b",
  darkSecondary: "#3C40C6",
  bgDark: "#272727",
  white: "#ffffff",
  black: "#111111",
  darkGrey: "#404040",
  gold: "#408effba",
  btnGold: "#E8751A",
  red: "#FB3E3E",
  cardBackground: "#0568f7ba",
  // cardBackgroundLight: "#4090ff74",
  cardBackgroundLight: "#4834DF",
  primaryWhite: "#ffffff",
  buttonBg: "#d6d2fb",
  blueText: "#3454D1FF",
  btnBlue: "#432ae1",
  green: "#43c530",
  textFieldGrey: "transparent !important",
  lightGray: "#7c7e8c",
  headingGray: "#44475b",
  grayBg: "#f8f8f8",
  borderGray: "#e9e9eb",
  paperGray: "#ececec",
  yellow: "#F4C724",
  purple: "#BB2CD9",
  blueLinearGradient: "linear-gradient(90deg, #0000FFFF 0%, #000000FF 100%)",
};

// backgroundColor: "#fffcf9";
