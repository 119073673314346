import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useMemo,
  useRef,
} from "react";
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import DynamicFormField from "../DynamicFormField";
import axios from "axios";
import { apiRouterCall } from "../../api/services";
import ScreenLoader from "../screenLoader";
import SuccessModal from "./SuccessModal";
import toast from "react-hot-toast";
import { AuthContext } from "../../context/auth";
import moment from "moment";
import FlexButton from "../FlexButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import ChatHead from "../../views/pages/support/ChatHead";
import NoDataFound from "../NoDataFound";
import MessageChatSection, {
  UploadAttachmentButton,
} from "../../views/pages/support/MessageChatSection";
import SupportFilter from "./SupportFilter";
import MessageModal from "./MessageModal";
import ErrorModal from "./ErrorModal";
import { colors } from "../../assets/colors";
import PageTitle from "../PageTitle";
import { IoMdArrowRoundBack } from "react-icons/io";

export const rightSide = {
  FORM: "FORM",
  CHAT: "CHAT",
};
export const currentFormToShow = {
  TICKET: "TICKET",
  CHATSCREEN: "CHATSCREEN",
};

export default function SupportModal({ open, close }) {
  const [subjectsList, setSubjectsList] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { userLoggedIn } = useContext(AuthContext);
  const [isNew, setIsNew] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedHead, setSelectedHead] = useState("");
  const [queriesList, setQueriesList] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [rightSideState, setRightSideState] = useState(rightSide.FORM);
  const { userData } = useContext(AuthContext);
  const [isError, setIsError] = useState(false);
  const [isReEnableInitialisation, setIsReEnableInitialisation] =
    useState(userLoggedIn);
  const [currentFormToShowState, setCurrentFormToShowState] = useState(
    currentFormToShow.TICKET
  );
  const [tabData, setTabData] = useState(0);

  const [formikValues, setFormikValues] = useState({});
  const [messageData, setMessageData] = useState({
    message: "",
    subject: "",
    image: "",
    blob: "",
    supportSubject: "",
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //function to get all the previous support list
  const getAllQueryListHandler = async () => {
    try {
      setQueriesList([]);
      const response = await apiRouterCall({
        method: "GET",
        endPoint: "queryTicketList",
        params: {
          filterBy: selectedFilter ? selectedFilter : null,
        },
      });
      if (response.data.responseCode === 200) {
        setQueriesList(response.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dates = new Set();
  const renderDate = (chat, dateNum) => {
    const timestampDate = moment(chat.created_at).format("DD MMM YYYY");
    // Add to Set so it does not render again
    dates.add(dateNum);
    const currentDate = moment().format("L");
    return (
      <>
        <Typography variant="body1">
          {moment(currentDate).isSame(moment(timestampDate).format("L"))
            ? "Today"
            : moment(currentDate)
                .subtract(1, "d")
                .isSame(moment(timestampDate).format("L"))
            ? "Yesterday"
            : timestampDate}
        </Typography>
      </>
    );
  };

  //function to render the final list according to the search field and sorting tab
  const queryData = useMemo(() => {
    if (search === "") {
      return queriesList;
    } else {
      return queriesList.filter((item) => {
        return (
          item?.ticketId?.toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          item?.message?.toLowerCase().indexOf(search?.toLowerCase()) !== -1
        );
      });
    }
  }, [queriesList, search]);

  //support query list
  const content = (
    <Fragment>
      {/* {rightSideState == rightSide.CHAT && (
        <Card
          sx={{
            padding: "10px",
          }}
        >
          <FlexButton
            color="primary"
            title="New Ticket"
            fullWidth={true}
            icon={<AddIcon />}
            onClick={() => {
              setIsNew(true);
              setSelectedHead("");
              setRightSideState(rightSide.CHAT);
              setRightSideState(rightSide.FORM);
            }}
          />
        </Card>
      )} */}
      <Card sx={{ padding: "10px", marginTop: "16px" }}>
        <Box display={"flex"} alignItems={"center"}>
          <TextField
            autoComplete="off"
            fullWidth
            placeholder="Search..."
            sx={{ marginBottom: "10px" }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <IconButton
            onClick={(e) => {
              handleClick(e);
              // setOpenFilter(true);
            }}
          >
            <FilterListIcon />
          </IconButton>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{
              "& .MuiMenu-paper": {
                background: colors.grayBg,
                left: "990px !important",
              },
            }}
          >
            <MenuItem
              onClick={(e) => {
                handleClose(e);
                setOpenFilter(false);
                setSelectedFilter("pending");
              }}
            >
              Pending
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                setOpenFilter(false);
                setSelectedFilter("resolved");
                handleClose(e);
              }}
            >
              Resolved
            </MenuItem>
          </Menu>
        </Box>
        <Box sx={{ maxHeight: 400, overflowY: "auto" }}>
          {queryData &&
            queryData.map((data, i) => {
              const dateNum = moment(data.created_at).format("ddMMyyyy");
              return (
                <Fragment key={`head${i}`}>
                  {dates.has(dateNum) ? null : renderDate(data, dateNum)}
                  <ChatHead
                    data={data}
                    selectedHead={selectedHead}
                    setIsNew={setIsNew}
                    setSelectedHead={setSelectedHead}
                    setRightSideState={setRightSideState}
                  />
                </Fragment>
              );
            })}
          {queryData && queryData.length === 0 && (
            <NoDataFound text={"No history found!"} />
          )}
        </Box>
      </Card>
    </Fragment>
  );

  useEffect(() => {
    getAllQueryListHandler();
    return () => {};
  }, [selectedFilter]);
  //function to get the support subjects list
  const getSupportSubjectListHandler = async (source) => {
    try {
      const response = await apiRouterCall({
        method: "GET",
        endPoint: "categoryList",
        source: source,
      });
      if (response.data.responseCode === 200) {
        setSubjectsList(response.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    getSupportSubjectListHandler(source);
    return () => {
      source.cancel();
    };
  }, []);

  //form fields with there relevant id that should match with the formik inital form schema
  const fromData = [
    {
      id: "name",
      title: " Name",
      placeHolder: "Enter your name",
      type: "text",
    },
    {
      id: "emailAddress",
      title: "Email Address",
      placeHolder: "Enter your email address",
      type: "email",
    },
    {
      id: "contact",
      title: "Mobile Number",
      placeHolder: "Enter your mobile number",
      type: "number",
    },
    {
      id: "supportSubject",
      title: "Support Subject",
      placeHolder: "Select the support subject...",
      type: "select",
      selectData:
        subjectsList &&
        subjectsList.map((x) => {
          return {
            label: x?.categoryType,
            value: x?._id,
          };
        }),
    },

    {
      id: "messageFile",
      title: "Message",
      placeHolder: "Enter your query",
      type: "messageFile",
    },
  ];

  //Validation schema using Yup
  const validationScema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "Invalid name")
      .required("Please enter your name."),
    emailAddress: Yup.string()
      .required("Please enter your email address.")
      .email("Please enter a valid email address."),
    contact: Yup.string()
      .required("Please enter your mobile number.")
      .min(10, "Please enter valid mobile number"),

    messageFile: Yup.string().required("Please enter your query."),
    supportSubject: Yup.string().required("Please select subject query."),
  });

  //formik form inital schema
  const initialValues = {
    name: userLoggedIn
      ? userData?.profile?.name
        ? userData?.profile?.name
        : ""
      : "",
    emailAddress: userLoggedIn
      ? userData?.profile?.email
        ? userData?.profile?.email
        : ""
      : "",
    contact: userLoggedIn
      ? userData?.profile?.mobile_number
        ? userData?.profile?.mobile_number
        : ""
      : "",
    messageFile: "",
    supportSubject: "",
  };
  console.log(userData?.profile?.mobile_number, "akjsdhjkasd");

  //function to raise a support ticket
  const submitHandler = async (values) => {
    console.log(submitHandler, "ahbsasjda");
    try {
      setIsProcessing(true);
      const data = userLoggedIn
        ? {
            categoryId: values?.supportSubject,
            image: values?.imageURL,
            message: values?.messageFile,
          }
        : {
            categoryId: values?.supportSubject,
            email: values?.emailAddress,
            mobileNumber: values?.contact,
            name: values?.name,
            message: values?.messageFile,
            image: values?.imageURL,
          };
      const response = await apiRouterCall({
        method: "POST",
        endPoint: userLoggedIn ? "raiseTicket" : "queryRaise",
        data: data,
      });
      if (response.data.responseCode === 200) {
        setIsSuccess(true);
      }
      setIsProcessing(false);
    } catch (error) {
      toast.error(error?.response?.data?.responseMessage);
      console.log(error);
      setIsProcessing(false);
    }
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
        <DialogTitle position="relative">
          <Typography variant="h3" textAlign="center">
            Need Help?
          </Typography>
          <Box sx={{ position: "absolute", top: "8px", right: "8px" }}>
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ paddingBottom: "30px" }}>
          {userLoggedIn && (
            <Grid container sx={{ justifyContent: "center", mb: 4 }}>
              <Box className="displayCenter">
                <Button
                  onClick={() => {
                    setTabData(0);
                  }}
                  variant="text"
                  style={{
                    color: tabData == 0 ? colors.btnBlue : colors.headingGray,
                    borderBottom:
                      tabData == 0 ? `1px solid ${colors.btnBlue}` : "none",
                    borderRadius: "0px",
                    textTransform: "none",
                    textWrap: "nowrap",
                  }}
                  fullWidth
                >
                  Existing Ticket
                </Button>
                <Button
                  onClick={() => {
                    setTabData(1);
                  }}
                  variant="text"
                  style={{
                    color: tabData == 1 ? colors.btnBlue : colors.headingGray,
                    borderBottom:
                      tabData == 1 ? `1px solid ${colors.btnBlue}` : "none",
                    borderRadius: "0px",
                    textTransform: "none",
                  }}
                  fullWidth
                >
                  New Request
                </Button>
              </Box>
            </Grid>
          )}
          <Grid container spacing={3}>
            {userLoggedIn ? (
              <>
                {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                  {content}
                </Grid> */}
                {tabData == 0 && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {rightSideState == rightSide.FORM ? (
                      content
                    ) : (
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{ flexDirection: "column" }}
                      >
                        <Grid container item xs={12}>
                          <Grid item xs={2}>
                            <IconButton
                              onClick={() => {
                                setRightSideState(rightSide.FORM);
                              }}
                            >
                              <IoMdArrowRoundBack />
                              <Typography
                                variant="body2"
                                sx={{
                                  color: colors.headingGray,
                                  fontWeight: "600",
                                  fontSize: 18,
                                  ml: 1,
                                }}
                              >
                                Back
                              </Typography>
                            </IconButton>
                          </Grid>
                        </Grid>
                        <MessageChatSection
                          isNew={isNew}
                          selectedHead={selectedHead}
                          callback={getAllQueryListHandler}
                          setIsNew={setIsNew}
                          setSelectedHead={setSelectedHead}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
                {tabData == 1 && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <DynamicFormField
                      initialValues={initialValues}
                      validationScema={validationScema}
                      fromData={fromData}
                      btnTitle="Submit"
                      btnDisabled={false}
                      onSubmit={(values) => submitHandler(values)}
                      innerRef={setFormikValues}
                      refType={"valueGetter"}
                      isReEnableInitialisation={isReEnableInitialisation}
                    />
                  </Grid>
                )}
              </>
            ) : (
              <>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  {userLoggedIn ? (
                    content
                  ) : (
                    <Box>
                      <img
                        src="/assets/images/Questions.png"
                        alt=""
                        width="100%"
                      />
                    </Box>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  {userLoggedIn && rightSideState == rightSide.CHAT ? (
                    <MessageChatSection
                      isNew={isNew}
                      selectedHead={selectedHead}
                      callback={getAllQueryListHandler}
                      setIsNew={setIsNew}
                      setSelectedHead={setSelectedHead}
                    />
                  ) : (
                    <DynamicFormField
                      initialValues={initialValues}
                      validationScema={validationScema}
                      fromData={fromData}
                      btnTitle="Submit"
                      btnDisabled={false}
                      onSubmit={(values) => submitHandler(values)}
                      innerRef={setFormikValues}
                      refType={"valueGetter"}
                      isReEnableInitialisation={isReEnableInitialisation}
                    />
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      {isProcessing && (
        <ScreenLoader
          open={isProcessing}
          close={() => setIsProcessing(false)}
        />
      )}
      {isSuccess && (
        <SuccessModal
          open={isSuccess}
          title={"Message Sent!"}
          close={() => {
            close();
            setIsSuccess(false);
          }}
          description={`Your query has been sent successfully! Our team will get back to shortly.`}
        />
      )}
      {openFilter && (
        <SupportFilter
          open={openFilter}
          close={() => {
            setOpenFilter(false);
            setSelectedFilter("");
          }}
          onClick={(data) => {
            setOpenFilter(false);
            setSelectedFilter(data);
          }}
          selectedFilter={selectedFilter}
        />
      )}
      {/* {messageData && messageData.blob !== "" && (
        <MessageModal
          subjectData={subjectsList}
          open={true}
          close={() => setMessageData({ ...messageData, blob: "", image: "" })}
          messageData={messageData}
          setMessageData={setMessageData}
          onClick={() => {
            if (!formikValues?.supportSubject) {
              setIsError(true);
              return;
            }
            submitHandler({
              supportSubject: formikValues?.supportSubject,
              image: formikValues.imageURL,
              message: formikValues.message,
            });
          }}
        />
      )} */}
      {isError && (
        <ErrorModal
          title={!messageData.subject ? "Oops! No subject :(" : "No message!"}
          description={
            !messageData.subject
              ? "Please select a support subject for better assistance."
              : "Ohoo! You forget to write a message."
          }
          open={isError}
          close={() => setIsError(false)}
        />
      )}
    </Fragment>
  );
}
