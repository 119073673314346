import { Avatar, Box, Card, Grid, Radio, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { colors } from "../../../assets/colors";
import { apiRouterCall } from "../../../api/services";
import axios from "axios";
import { getRandomAvtar } from "../../../utils";

export default function SupportOptions({ value, onClick }) {
  const [subjectsList, setSubjectsList] = useState([]);

  //function to get the support subjects list
  const getSupportSubjectListHandler = async (source) => {
    try {
      const response = await apiRouterCall({
        method: "GET",
        endPoint: "categoryList",
        source: source,
      });
      if (response.data.responseCode === 200) {
        setSubjectsList(response.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    getSupportSubjectListHandler(source);
    return () => {
      source.cancel();
    };
  }, []);

  return (
    <Box className="displayStart" height={"100%"}>
      <Grid container spacing={0}>
        <Grid
          item
          lg={1}
          sm={1}
          md={1}
          xs={1}
          display={"flex"}
          alignItems={"end"}
          justifyContent={"center"}
        >
          {" "}
          <Box sx={{ height: "inherit" }}>
            <Avatar
              sx={{
                height: "25px",
                width: "25px",
              }}
              src={getRandomAvtar("support")}
            />
          </Box>
        </Grid>
        <Grid item lg={7} sm={7} md={7} xs={7}>
          <Card sx={{ padding: "10px", borderRadius: "10px 10px 10px 0px" }}>
            <Box>
              <Typography
                variant="body2"
                fontWeight={600}
                sx={{ color: colors.btnGold }}
              >
                Support
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                Hello! Please choose a support subject below to proceed.
              </Typography>
              <Box mt={1}>
                {subjectsList &&
                  subjectsList.map((data, i) => {
                    return (
                      <Box
                        className="displayStart"
                        sx={`optionsSupport${i}`}
                        mt={0.5}
                      >
                        <Radio
                          sx={{ padding: "0px", marginRight: "6px" }}
                          checked={value === data._id}
                          onClick={() => onClick(data._id)}
                        />
                        <Typography>{data.categoryType}</Typography>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item lg={4} sm={4} md={4} xs={4}></Grid>
      </Grid>
    </Box>
  );
}
