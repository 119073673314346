import React from "react";
import { useContext } from "react";
import ThemeContext from "../context/themeContext";
import { Box } from "@mui/material";

export default function Logo(props) {
  const themeSettings = useContext(ThemeContext);
  return (
    <Box
      component={"img"}
      className="Logoimg"
      src={
        // themeSettings.settings.theme === "DARK"
        //   ? "/assets/logo/logo_dark.svg"
        "/assets/icons/logo.gif"

        // "/assets/logo/logo_dark.svg"
      }
      {...props}
      alt="site_logo"
    />
  );
}
