import { Box, ThemeProvider, Typography } from "@mui/material";
import { useContext } from "react";
import { createAppTheme } from "./theme";
import ThemeContext from "./context/themeContext";
import { routes } from "./routes";
import { RenderRoutes } from "./routes/renderRoutes";
import "react-phone-input-2/lib/style.css";
import { Toaster } from "react-hot-toast";
import CookieConsent from "react-cookie-consent";
import AuthContext from "./context/auth";
import FlexLayerContext from "./context/flexLayer";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { colors } from "./assets/colors";
// import { Offline, Online } from "react-detect-offline";

window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {
  const themeSettings = useContext(ThemeContext);
  const theme = createAppTheme({
    theme: themeSettings.settings.theme,
  });

  return (
    <>
      <div className="App">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <AuthContext>
            <FlexLayerContext>
              <ThemeProvider theme={theme}>
                <RenderRoutes data={routes} />
                <Toaster
                  position="top-right"
                  autoClose={4000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  // theme="light"
                  theme={theme.palette.type}
                />
                <CookieConsent
                  style={{
                    background: colors.primaryWhite,
                    color: colors.blueText,
                    border: `1px solid ${colors.borderGray}`,
                    fontSize: "14px",
                  }}
                  buttonStyle={{
                    color: colors.primaryWhite,
                    fontSize: "13px",
                    background: colors.blueText,
                    width: "140px",
                    height: "36px",
                  }}
                >
                  This website uses cookies to enhance the user experience.
                </CookieConsent>
              </ThemeProvider>
            </FlexLayerContext>
          </AuthContext>
        </LocalizationProvider>
      </div>
    </>
  );
}

export default App;
