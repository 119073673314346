import {
  Box,
  Divider,
  IconButton,
  Tooltip,
  Badge,
  Toolbar,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { React, useContext, useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import NotificationModal from "../../../components/modals/NotificationModal";
import { AuthContext } from "../../../context/auth";
import { FlexLayerContext } from "../../../context/flexLayer";
import LogoutIcon from "@mui/icons-material/Logout";
import toast from "react-hot-toast";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { colors } from "../../../assets/colors";
import FlexButton from "../../../components/FlexButton";

import SavingsIcon from "@mui/icons-material/Savings";
import io from "socket.io-client";

import { notification_socket_url } from "../../../api";
import SupportButton from "../../../components/SupportButton";
import { HiUserGroup } from "react-icons/hi";
import SupportModal from "../../../components/modals/SupportModal";
export default function RightOptions({ themeSettings, sx }) {
  const { userLoggedIn } = useContext(AuthContext);
  const { notificationData } = useContext(FlexLayerContext);
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const [openLogout, setOpenLogout] = useState(false);
  const auth = useContext(AuthContext);
  const navData = [
    {
      title: "Profile",
      icon: (
        <AccountCircleIcon
          style={{ fontSize: "26px", color: colors.blueText }}
        />
      ),
      onClick: () => {
        navigate("/user-account-settings");
      },
    },
    {
      title: "Support",
      icon: (
        <SupportAgentIcon style={{ fontSize: "26px", color: colors.purple }} />
      ),
      onClick: () => {
        setIsSupportModalOpen(true);
      },
    },
    {
      title: "Saving Account",
      icon: <SavingsIcon style={{ fontSize: "26px", color: colors.green }} />,
      onClick: () => {
        navigate("/wallet");
      },
    },
    {
      title: "Offer and rewards",
      icon: <HiUserGroup style={{ fontSize: "26px", color: colors.yellow }} />,
      onClick: () => {
        navigate("/refer-earn");
      },
    },
    {
      title: "Notifications",
      icon: (
        <NotificationsIcon style={{ fontSize: "26px", color: colors.red }} />
      ),
      onClick: () => {
        setIsActive(true);
      },
    },
    {
      title: "Logout",
      icon: <LogoutIcon style={{ fontSize: "26px", color: colors.red }} />,
      onClick: () => {
        setOpenLogout(true);
      },
    },
  ];

  const notificationModal = useMemo(() => {
    return (
      <NotificationModal
        open={isActive}
        close={() => setIsActive(false)}
        data={notificationData?.data}
      />
    );
  }, [isActive]);
  const logoutHandler = () => {
    auth.userLogIn(false, null);
    toast.success("You have successfully logged out!");
    navigate("/");
  };
  return (
    <Box sx={sx}>
      {!userLoggedIn && (
        <Grid container item xs={12} flexDirection={"row"} minWidth={"180px"}>
          <Grid item xs={6}>
            <FlexButton
              title="Login"
              color="secondary"
              disabled={false}
              fullWidth={false}
              sx={{
                marginRight: "10px",
              }}
              onClick={() => navigate("/login")}
            />
          </Grid>
          <Grid item xs={6}>
            <FlexButton
              title="Sign Up"
              color="primary"
              disabled={false}
              fullWidth={false}
              onClick={() => navigate("/signup")}
            />
          </Grid>
        </Grid>
      )}
      {userLoggedIn && (
        <Grid container sx={{ flexWrap: "wrap", width: 300 }}>
          {navData?.map((item) => {
            return (
              <Box
                sx={{
                  width: 300,
                  mt: 1,
                }}
                onClick={() => {
                  item?.onClick();
                }}
              >
                <Button
                  variant="text"
                  fullWidth
                  className="displayStart"
                  sx={{ justifyContent: "flex-start", gap: 1 }}
                >
                  {item?.icon}
                  <Typography variant="h6" sx={{ color: colors.headingGray }}>
                    {item?.title}
                  </Typography>
                </Button>
              </Box>
            );
          })}

          {isSupportModalOpen && (
            <SupportModal
              open={isSupportModalOpen}
              close={() => setIsSupportModalOpen(false)}
            />
          )}
          {/* {userLoggedIn && (
            <>
              <Tooltip title="Logout">
                <IconButton
                  onClick={() => {
                    setOpenLogout(true);
                  }}
                  sx={{
                    color: colors.primaryWhite,
                    transition: "all 200ms ease-in-out 50ms",
                    ":hover": {
                      color: colors.red,
                    },
                  }}
                >
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </>
          )} */}
        </Grid>
      )}

      {isActive && notificationModal}
      {openLogout && (
        <ConfirmationModal
          open={openLogout}
          title="Logout!"
          description="Are you sure want to logout?"
          onClick={logoutHandler}
          close={() => setOpenLogout(false)}
        />
      )}
    </Box>
  );
}
