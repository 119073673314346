import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import Page from "../../../components/Page";
import { apiRouterCall } from "../../../api/services";
import ScreenLoader from "../../../components/screenLoader";
import NoDataFound from "../../../components/NoDataFound";

export default function BNPLPolicy({ open }) {
  const [isLoading, setIsLoading] = useState(true);
  const [allData, setAllData] = useState({});
  console.log(">>>>>>>allData", allData);
  const dataHandler = async () => {
    try {
      setIsLoading(true);
      const response = await apiRouterCall({
        method: "GET",
        endPoint: "staticContentList",
      });
      if (response.data.responseCode === 200) {
        setIsLoading(false);
        const filterData = response?.data?.result?.find(
          (item) => item?.type === "BNPLPolicy"
        );
        setAllData(filterData);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    dataHandler();
  }, []);

  return (
    <Page title={`${allData?.title} Tarality`}>
      {isLoading && <ScreenLoader open={isLoading} />}
      <Box
        className={open ? "" : "mainContainer"}
        pt={!open && 15}
        pb={10}
        sx={{
          "& li": {
            color: "#000 !important",
          },
        }}
      >
        <Typography variant="h4" gutterBottom>
          {allData?.title}
        </Typography>
        <Typography
          variant="h6"
          dangerouslySetInnerHTML={{ __html: allData && allData.description }}
        ></Typography>
      </Box>
      {!isLoading && !allData?.title && <NoDataFound />}
    </Page>
  );
}
