import {
  TextField,
  FormControl,
  Typography,
  FormHelperText,
  InputAdornment,
  IconButton,
  Box,
  Select,
  MenuItem,
  Grid,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { Formik, Form } from "formik";
import React, { useRef, useState } from "react";
import FlexButton from "./FlexButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckIcon from "@mui/icons-material/Check";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { colors } from "../assets/colors";
import { IoMdAddCircleOutline } from "react-icons/io";
import { apiRouterCall } from "../api/services";
import toast from "react-hot-toast";
import ImagePreviewModal from "./modals/ImagePreviewModal";
import { TiTick } from "react-icons/ti";

export default function DynamicFormField({
  validationScema,
  initialValues,
  onSubmit,
  fromData,
  btnTitle,
  btnDisabled,
  extraComponent,
  type,
  setOpen,
  innerRef,
  refType,
  isReEnableInitialisation,
}) {
  return (
    <Formik
      enableReinitialize={isReEnableInitialisation}
      initialValues={initialValues}
      innerRef={
        refType
          ? (formikActions) =>
              formikActions ? innerRef(formikActions.values) : innerRef({})
          : innerRef && innerRef
      }
      initialStatus={{
        success: false,
        successMsg: "",
      }}
      validationSchema={validationScema}
      validate={(values) => {
        const errors = {};
        if (values.relationType === "Others" && values.others === "") {
          errors.others = "Other field is required when Relation is 'Other'";
        }
        return errors;
      }}
      onSubmit={(values) => onSubmit(values)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            {fromData &&
              fromData.map((item) => {
                return (
                  <Grid item xs={12} sm={type === "nominee" ? 6 : 12}>
                    <FormTextFiield
                      key={`formFi${item.id}`}
                      item={item}
                      values={values}
                      touched={touched}
                      errors={errors}
                      btnTitle={btnTitle}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setOpen={setOpen}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                );
              })}
          </Grid>

          {extraComponent && extraComponent}

          <FlexButton
            title={btnTitle}
            type="submit"
            color="primary"
            fullWidth={true}
            disabled={btnDisabled}
          />
        </Form>
      )}
    </Formik>
  );
}

const FormTextFiield = ({
  item,
  values,
  handleBlur,
  handleChange,
  touched,
  errors,
  btnTitle,
  setOpen,
  setFieldValue,
}) => {
  const [isHide, setIsHide] = useState(true);
  const [checkPasswordStrength, setCheckPasswordStrength] = useState({
    isSpecial: false,
    isStrongLength: false,
    isDigit: false,
    isUpperCase: false,
    isLowerCase: false,
  });
  const fileInputRef = useRef();
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const uploadFileHandler = async (file) => {
    try {
      setIsImageUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      const response = await apiRouterCall({
        method: "POST",
        endPoint: "uploadImageFile",
        data: formData,
      });
      setIsImageUploading(false);
      if (response.data.responseCode === 200) {
        return response?.data?.result?.url;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.responseMessage);
      setIsImageUploading(false);
      return false;
    }
  };

  //function to check user's password strength
  function passwordFunction(value) {
    setCheckPasswordStrength({
      isSpecial: value.match(/[!@#$%^&*(),.?":{}|<>]/),
      isStrongLength: value.match("^(.{8,})"),
      isDigit: value.match("^(?=.*?[0-9])"),
      isUpperCase: value.match("^(?=.*?[A-Z])"),
      isLowerCase: value.match("^(?=.*?[a-z])"),
    });
  }

  return (
    <FormControl fullWidth key={item.id} sx={{ marginBottom: "12px" }}>
      {item.others !== "others" && (
        <Typography variant="body1" sx={{ color: colors.black }}>
          {item.title}
        </Typography>
      )}

      {item.type === "select" && (
        <Box sx={{ height: "100%", width: "100%", position: "relative" }}>
          {!values[item.id] && (
            <Box
              component={"label"}
              sx={{
                position: "absolute",
                bottom: "6px",
                zIndex: "11",
                color: colors.lightGray,
              }}
            >
              {item.placeHolder ? item.placeHolder : "--"}
            </Box>
          )}
          <Select
            fullWidth
            name={item.id}
            value={values[item.id]}
            error={Boolean(touched[item.id] && errors[item.id])}
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              console.log(">>>>>e>>>>", e.target.value === "interprise");
              if (e.target.value === "interprise") {
                setOpen(true);
              }
            }}
            placeholder={item.placeHolder}
            sx={{
              "&.MuiOutlinedInput-root": {
                background: "transparent",
              },
            }}
          >
            {item.selectData &&
              item.selectData.map((x, i) => {
                return (
                  <MenuItem value={x.value} key={`${x.value}-${i}-${item.id}`}>
                    {x.label}
                  </MenuItem>
                );
              })}
          </Select>
        </Box>
      )}

      {item.type == "messageFile" && (
        <TextField
          autoComplete="off"
          sx={{
            marginTop: "5px",
            "& .MuiOutlinedInput-input": {
              backgroundColor: `${colors.primaryWhite} !important`,
            },
            py: 2,
          }}
          variant="standard"
          name={item.id}
          type={isHide ? item.type : "text"}
          rows={item.title === "messageFile" ? 5 : null}
          multiline={item.title === "messageFile" ? true : false}
          // inputProps={{ maxLength: 60 }}
          value={values[item.id]}
          error={Boolean(touched[item.id] && errors[item.id])}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
            if (
              item.id === "password" &&
              (btnTitle === "Sign up" || btnTitle === "Change")
            ) {
              passwordFunction(e.target.value);
            }
          }}
          id={item.id}
          placeholder={item.placeHolder}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isImageUploading ? (
                  <CircularProgress sx={{ color: "green" }} size={20} />
                ) : (
                  <>
                    <input
                      type="file"
                      ref={fileInputRef}
                      hidden
                      onChange={async (e) => {
                        const fileUrl = await uploadFileHandler(
                          e?.target?.files[0]
                        );
                        setFieldValue("imageURL", fileUrl);
                        setIsImageModalOpen(true);
                        if (fileInputRef?.current) {
                          fileInputRef.current.value = null;
                        }
                      }}
                    />
                    <IconButton
                      onClick={() => {
                        fileInputRef?.current?.click();
                      }}
                      disabled={values?.imageURL}
                    >
                      {values?.imageURL ? (
                        <TiTick style={{ color: colors.green }} />
                      ) : (
                        <IoMdAddCircleOutline />
                      )}
                    </IconButton>
                  </>
                )}
              </InputAdornment>
            ),
          }}
        />
      )}

      {item.type !== "messageFile" &&
        item.type !== "select" &&
        item.others !== "others" && (
          <TextField
            autoComplete="off"
            sx={{
              marginTop: "5px",
              "& .MuiOutlinedInput-input": {
                backgroundColor: `${colors.primaryWhite} !important`,
              },
              py: 2,
            }}
            variant="standard"
            name={item.id}
            type={isHide ? item.type : "text"}
            rows={item.title === "Message" ? 5 : null}
            multiline={item.title === "Message" ? true : false}
            // inputProps={{ maxLength: 60 }}
            value={values[item.id]}
            error={Boolean(touched[item.id] && errors[item.id])}
            onBlur={handleBlur}
            onChange={(e) => {
              if (item?.id == "panNumber") {
                setFieldValue(item.id, e?.target?.value.toUpperCase());
                return;
              }
              handleChange(e);

              if (
                item.id === "password" &&
                (btnTitle === "Sign up" || btnTitle === "Change")
              ) {
                passwordFunction(e.target.value);
              }
            }}
            id={item.id}
            placeholder={item.placeHolder}
            fullWidth
            InputProps={
              item.type === "password"
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setIsHide(!isHide)}>
                          {isHide ? (
                            <VisibilityOffIcon
                              sx={{
                                color: colors.cardBackground,
                                ":hover": {
                                  color: colors.cardBackgroundLight,
                                },
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              sx={{
                                color: colors.cardBackground,
                                ":hover": {
                                  color: colors.cardBackgroundLight,
                                },
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : {}
            }
          />
        )}
      {values.relationType === "Others" && item.others === "others" && (
        <Typography variant="body1" sx={{ color: colors.black }}>
          {item.title}
        </Typography>
      )}
      {values.relationType === "Others" && item.others === "others" && (
        <TextField
          autoComplete="off"
          sx={{
            marginTop: "5px",
          }}
          name={item.id}
          type={isHide ? item.type : "text"}
          rows={item.title === "Message" ? 5 : null}
          multiline={item.title === "Message" ? true : false}
          // inputProps={{ maxLength: 60 }}
          value={values[item.id]}
          error={Boolean(touched[item.id] && errors[item.id])}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
          id={item.id}
          placeholder={item.placeHolder}
          fullWidth
        />
      )}

      <FormHelperText error>
        {touched[item.id] && errors[item.id]}
      </FormHelperText>

      {values["password"] !== "" &&
        item.id === "password" &&
        (btnTitle === "Sign up" || btnTitle === "Change") && (
          <Box mt={0.5}>
            <Typography variant="body1">Your password must contain:</Typography>
            <Box
              className={`displayStart ${
                checkPasswordStrength.isStrongLength ? "green" : "waiting"
              } icocmargin`}
            >
              {checkPasswordStrength && checkPasswordStrength.isStrongLength ? (
                <CheckIcon fontSize="15px" />
              ) : (
                <FiberManualRecordIcon fontSize="15px" />
              )}{" "}
              <Typography variant="body1">At least 10 characters</Typography>
            </Box>
            <Box
              className={`displayStart ${
                checkPasswordStrength.isLowerCase ? "green" : "waiting"
              } icocmargin`}
            >
              {checkPasswordStrength && checkPasswordStrength.isLowerCase ? (
                <CheckIcon fontSize="15px" />
              ) : (
                <FiberManualRecordIcon fontSize="15px" />
              )}
              <Typography variant="body1">Lower case letter (a-z)</Typography>
            </Box>
            <Box
              className={`displayStart ${
                checkPasswordStrength.isUpperCase ? "green" : "waiting"
              } icocmargin`}
            >
              {checkPasswordStrength && checkPasswordStrength.isUpperCase ? (
                <CheckIcon fontSize="15px" />
              ) : (
                <FiberManualRecordIcon fontSize="15px" />
              )}
              <Typography variant="body1">Upper case letter (A-Z)</Typography>
            </Box>
            <Box
              className={`displayStart ${
                checkPasswordStrength.isSpecial ? "green" : "waiting"
              } icocmargin`}
            >
              {checkPasswordStrength && checkPasswordStrength.isSpecial ? (
                <CheckIcon fontSize="15px" />
              ) : (
                <FiberManualRecordIcon fontSize="15px" />
              )}
              <Typography variant="body1">
                {`Special characters (!@#$%ˆ&*)`}
              </Typography>
            </Box>
          </Box>
        )}
      {isImageModalOpen && (
        <ImagePreviewModal
          open={isImageModalOpen}
          close={() => {
            setIsImageModalOpen(false);
          }}
          img={values?.imageURL}
          setFieldValue={setFieldValue}
          fileInputRef={fileInputRef}
        />
      )}
    </FormControl>
  );
};
