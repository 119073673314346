import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Checkbox,
  CircularProgress,
  Container,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { colors } from "../../../assets/colors";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import OTPInput from "otp-input-react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { RxCross2 } from "react-icons/rx";
import { apiRouterCall } from "../../../api/services";
import { MdEdit } from "react-icons/md";
import toast from "react-hot-toast";
import { AuthContext } from "../../../context/auth";
import { useNavigate } from "react-router";
import PrivacyModal from "../../../components/PrivacyModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: 400, md: 800 },
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  height: 540,
};
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const validationScemaEmailOnly = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email address.")
    .matches(emailRegex, "Please enter valid email.")
    .email("Please enter a valid email address."),
});
const validationScemaEmail = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email address.")
    .matches(emailRegex, "Please enter valid email.")
    .email("Please enter a valid email address."),
  password: Yup.string()
    .required("Please enter your password.")
    .min(8, "Minimum 8 digit are required.")
    .max(20, "Maximum 20 digit are allowed."),
});
const validationScemaOtp = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email address.")
    .matches(emailRegex, "Please enter valid email.")
    .email("Please enter a valid email address."),
  otp: Yup.string()
    .required("Please enter your OTP.")
    .min(6, "Please enter a valid OTP."),
});

const validationScemaConfirmPassword = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email address.")
    .matches(emailRegex, "Please enter valid email.")
    .email("Please enter a valid email address."),
  password: Yup.string()
    .required("Please enter your password.")
    .max(20, "Maximum 20 digit are allowed")
    .matches(
      /^(?=.*[!@#$%^&*()_+}{:;"'?><,./\[\]-])(?=.*[0-9])(?=.*[A-Z]).{8,}$/,
      "Password must contain at least one special character, one number, and one uppercase letter"
    )
    .min(8, "Minimum 8 digit are required."),
  confirmPassword: Yup.string()
    .required("Confirm password is required.")
    .oneOf([Yup.ref("password")], "Passwords must match"),
  refCode: Yup.string(),
});
const initialValuesEmail = {
  email: "",
  password: "",
};
const initialValuesOtp = {
  email: "",
  otp: "",
};
const initialValuesConfirmPassword = {
  email: "",
  password: "",
  confirmPassword: "",
  refCode: "",
};

const fieldToBeShown = {
  OTP: "OTP",
  PASSWORD: "PASSWORD",
  NONE: "NONE",
  VERIFYAFTEROTP: "VERIFYAFTEROTP",
  CONFIRMPASSWORD: "CONFIRMPASSWORD",
  VERIFYSIGNUPOTP: "VERIFYSIGNUPOTP",
};

const LoginRegisterModal = ({ open, setOpen }) => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [timer, setTimer] = useState(120);
  const [isObsecure, setIsObsecure] = useState(false);
  const [fieldState, setfieldState] = useState(fieldToBeShown.NONE);
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [verifyModalMessage, setVerifyModalMessage] = useState("");
  const [openOTPVerify, setOpenVerifyOTP] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordObsecure, setIsPasswordObsecure] = useState(false);
  const [isConfirmPasswordObsecure, setIsConfirmPasswordObsecure] =
    useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);
  const [isResendEnable, setIsResendEnable] = useState(true);

  const [tAndCAccepted, setTAndCAccepted] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const ref = useRef();
  useEffect(() => {
    // const storedTimer = sessionStorage.getItem("timer");
    // if (storedTimer) {
    //   if (storedTimer == 0) {
    //     sessionStorage.removeItem("timer");
    //   } else {
    //     setTimer(parseInt(storedTimer));
    //   }
    // }

    if (timer > 0 && open) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          const newTimer = prevTimer - 1;
          sessionStorage.setItem("timer", newTimer.toString());
          return newTimer;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timer, open]);

  useEffect(() => {
    if (!open) {
      sessionStorage.removeItem("timer");
      setTimer(120);
      setIsForgotPassword(false);
      setIsButtonLoading(false);
      ref?.current?.resetForm();
    }
  }, [open]);
  useEffect(() => {
    const getIpData = async () => {
      try {
        const responseIp = await apiRouterCall({
          method: "GET",
          // url: "https://api.ipify.org?format=json",
          url: "https://ipinfo.io/json",
        });
        if (responseIp?.data?.ip) {
          setIpAddress(responseIp?.data?.ip);
        } else {
          setIpAddress("");
        }
      } catch (error) {
        setIpAddress("");
      }
    };
    getIpData();
  }, []);
  const clearTime = () => {
    setTimer(120);
    sessionStorage.removeItem("timer");
  };

  const resendOtp = async (email) => {
    setTimer(120);
    try {
      setIsProcessing(true);
      const response = await apiRouterCall({
        endPoint: "resendOtp",
        method: "PUT",
        data: {
          email: email,
        },
      });
      setOtp("");
      setIsProcessing(false);

      if (response.data.responseCode === 200) {
        toast.success(response.data.responseMessage);
        clearTime();
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      setIsProcessing(false);
      console.log("error", error);
      if (error.response.data.responseCode === 405) {
        toast.error(error.response.data.responseMessage);
      }
    }
  };

  const checkIfUserExist = async (values) => {
    sessionStorage.removeItem("timer");
    try {
      setIsButtonLoading(true);
      const response = await apiRouterCall({
        method: "GET",
        endPoint: "chackEmailExist",
        params: {
          email: values.email?.toLowerCase(),
        },
      });
      if (response?.data?.responseCode == 404) {
        setfieldState(fieldToBeShown.CONFIRMPASSWORD);
      } else if (response?.data?.responseCode == 200) {
        setfieldState(fieldToBeShown.PASSWORD);
      }
      setIsButtonLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const loginHandler = async (values) => {
    sessionStorage.removeItem("timer");
    setIsButtonLoading(true);
    try {
      const login = await apiRouterCall({
        method: "POST",
        endPoint: "loginOtp",
        data: {
          email: values.email?.toLowerCase(),
          password: values.password,
        },
      });
      if (login.data.responseCode === 200) {
        setVerifyModalMessage(login?.data?.responseMessage);
        setTimer(120);
        sessionStorage.setItem("timer", 120);
        setfieldState(fieldToBeShown.VERIFYAFTEROTP);
        setIsButtonLoading(false);
        toast.success(login?.data?.responseMessage);
        if (login?.data?.responseMessage?.includes("google")) {
          setIsResendEnable(false);
        }
      } else {
        toast.error(login.data.responseMessage);
        setIsButtonLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.responseMessage);
      setIsButtonLoading(false);
    }
  };
  const verifyOTPHandler = async (values) => {
    try {
      setIsButtonLoading(true);
      setIsProcessing(true);
      const response = await apiRouterCall({
        method: "PUT",
        endPoint: "verifyLoginOtp",
        data: {
          email: values.email?.toLowerCase(),
          otp: Number(values?.otp),
          ip_address: ipAddress ? ipAddress : undefined,
        },
      });
      ref?.current?.setFieldValue("otp", "");
      if (response.data.responseCode === 200) {
        auth.userLogIn(true, response?.data?.result?.token);
        setOtp("");
        window.sessionStorage.setItem(
          "user_id",
          response?.data?.result?.user_id
        );
        sessionStorage.removeItem("timer");
        toast.success(response?.data?.responseMessage);
        navigate("/dashboard", { replace: true });
        setOpenVerifyOTP(false);
        setIsProcessing(false);
        setIsButtonLoading(false);
        setIsResendEnable(true);
      } else {
        setOtp("");
        toast.error(
          response && response?.data && response?.data?.responseMessage
        );
        setIsProcessing(false);
        setIsButtonLoading(false);
      }
      setIsProcessing(false);
      setIsButtonLoading(false);
    } catch (error) {
      setOtp("");
      if (error?.response?.data?.responseCode) {
        toast.error(error?.response?.data?.responseMessage);
      } else {
        toast.error(error?.response?.data?.error.message);
      }
      setIsProcessing(false);
      setIsButtonLoading(false);
    }
  };
  const signUpHandler = async (values) => {
    setIsButtonLoading(true);
    try {
      setIsProcessing(true);
      const response = await apiRouterCall({
        endPoint: "register",
        method: "POST",
        data: {
          email: values.email?.toLowerCase(),
          password: password,
          confirmPassword: confirmPassword,
          parent_ref_code: values.refCode ? values.refCode : undefined,
          userType: "user",
        },
      });
      if (response.data.responseCode === 200) {
        setfieldState(fieldToBeShown.VERIFYSIGNUPOTP);
        toast.success(response.data.responseMessage);
        setIsButtonLoading(false);
      } else {
        toast.error(response.data.message);
        setIsButtonLoading(false);
      }
      setIsProcessing(false);
    } catch (error) {
      setIsProcessing(false);
      setIsButtonLoading(false);
      if (error.response.data.responseCode === 405) {
        toast.error(error.response.data.responseMessage);
      }
    }
  };
  const verifySignupOtp = async (values) => {
    try {
      setIsButtonLoading(true);
      setIsProcessing(true);
      const response = await apiRouterCall({
        method: "POST",
        endPoint: "verifyOtp",
        data: {
          email: values.email?.toLowerCase(),
          otp: values.otp,
        },
      });
      ref?.current?.setFieldValue("otp", "");
      if (response.data.responseCode === 200) {
        setOtp("");

        toast.success(response?.data?.responseMessage);
        sessionStorage.removeItem("timer");
        if (isForgotPassword) {
          setfieldState(fieldToBeShown.CONFIRMPASSWORD);
        } else {
          setfieldState(fieldToBeShown.NONE);
        }
        setIsButtonLoading(false);
      } else {
        toast.error(response.data.responseMessage);
        setIsButtonLoading(false);
      }
      setIsProcessing(false);
      setIsButtonLoading(false);
    } catch (error) {
      setOtp("");
      console.log(error);
      toast.error(error.response.data.responseMessage);
      setIsProcessing(false);
      setIsButtonLoading(false);
    }
  };
  useEffect(() => {
    clearTime();
  }, [setfieldState]);
  useEffect(() => {
    if (ref?.current) {
      ref?.current?.resetForm();
    }
    setfieldState(fieldToBeShown.NONE);
    setIsResendEnable(true);
    clearTime();
  }, [open]);

  const generateOTPHandler = async (values) => {
    try {
      setIsProcessing(true);
      setIsButtonLoading(true);
      const response = await apiRouterCall({
        method: "POST",
        endPoint: "forgotPassword",
        data: {
          email: values.email?.toLowerCase(),
        },
      });
      if (response.data.responseCode === 200) {
        toast.success(response.data.responseMessage);
        setfieldState(fieldToBeShown.VERIFYSIGNUPOTP);
        setIsButtonLoading(false);
        setOtp("");
      } else {
        toast.error(response.data.responseMessage);
        setIsButtonLoading(false);
        setOtp("");
      }
      setIsProcessing(false);
      setIsButtonLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.responseMessage);
      setIsProcessing(false);
      setIsButtonLoading(false);
    }
  };
  const changePasswordHandler = async (values) => {
    try {
      setIsProcessing(true);
      setIsButtonLoading(true);
      const response = await apiRouterCall({
        endPoint: "resetPassword",
        method: "PUT",
        data: {
          password: values.password,
          confirmPassword: values.confirmPassword,
          email: values?.email,
        },
      });
      setIsButtonLoading(false);
      if (response.data.responseCode === 200) {
        sessionStorage.removeItem("timer");
        toast.success(response.data.responseMessage);
        window.sessionStorage.removeItem("user_id");
        handleClose();
      } else {
        toast.error(response.data.responseMessage);
      }
      setIsProcessing(false);
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            disabled={isButtonLoading}
            onClick={(e) => {
              handleClose(e);
            }}
            sx={{
              position: "absolute",
              right: "10px",
            }}
          >
            <RxCross2 />
          </IconButton>
          <Grid
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid
              container
              item
              md={5.8}
              xs={12}
              sx={{
                display: { xs: "none", md: "flex" },

                borderRadius: 10,
              }}
            >
              <Box
                component={"img"}
                src="/assets/LandingPage1/AuthAsset/login.svg"
                sx={{ width: "80%" }}
              />
            </Grid>
            <Box
              sx={{
                height: 460,
                width: "1px",
                backgroundColor: colors.lightGray,
                display: { xs: "none", md: "flex" },
              }}
            ></Box>
            <Grid
              container
              item
              md={5.8}
              xs={12}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ height: 540 }}
            >
              <Box className="displayCenterColumn" sx={{ width: "100%" }}>
                <Typography
                  variant="h2"
                  sx={{
                    color: colors.headingGray,
                    height: "100px",
                  }}
                >
                  {isForgotPassword ? "Reset Password" : "Welcome to Tarality"}
                </Typography>
                <Formik
                  initialValues={
                    fieldState == fieldToBeShown.VERIFYAFTEROTP
                      ? initialValuesOtp
                      : fieldState == fieldToBeShown.OTP
                      ? initialValuesOtp
                      : fieldState == fieldToBeShown.PASSWORD
                      ? initialValuesEmail
                      : fieldState == fieldToBeShown.CONFIRMPASSWORD
                      ? initialValuesConfirmPassword
                      : validationScemaEmailOnly
                  }
                  // validationScemaConfirmPassword
                  onSubmit={(values) => {
                    if (fieldState == fieldToBeShown.NONE) {
                      if (isForgotPassword) {
                        generateOTPHandler(values);
                      } else {
                        checkIfUserExist(values);
                      }
                    } else if (fieldState == fieldToBeShown.VERIFYAFTEROTP) {
                      verifyOTPHandler(values);
                    } else if (fieldState == fieldToBeShown.PASSWORD) {
                      loginHandler(values);
                    } else if (fieldState == fieldToBeShown.OTP) {
                      signUpHandler(values);
                    } else if (fieldState == fieldToBeShown.CONFIRMPASSWORD) {
                      if (isForgotPassword) {
                        changePasswordHandler(values);
                      } else {
                        signUpHandler(values);
                      }
                    } else if (fieldState == fieldToBeShown.VERIFYSIGNUPOTP) {
                      verifySignupOtp(values);
                    }
                  }}
                  validationSchema={
                    fieldState == fieldToBeShown.NONE
                      ? validationScemaEmailOnly
                      : fieldState == fieldToBeShown.PASSWORD
                      ? validationScemaEmail
                      : fieldState == fieldToBeShown.OTP
                      ? validationScemaOtp
                      : fieldState == fieldToBeShown.VERIFYAFTEROTP
                      ? validationScemaOtp
                      : fieldState == fieldToBeShown.VERIFYSIGNUPOTP
                      ? validationScemaOtp
                      : fieldState == fieldToBeShown.CONFIRMPASSWORD
                      ? validationScemaConfirmPassword
                      : validationScemaEmailOnly
                  }
                  innerRef={ref}
                >
                  {({
                    errors,
                    touched,
                    isValidating,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    values,
                  }) => (
                    <Box
                      component={Form}
                      sx={{
                        width: "100%",
                        gap: 4,
                        // pl: { xs: 2.5, md: 5 },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Container maxWidth="xl">
                        <Box
                          className="displayStartColumn"
                          sx={{
                            width: "100%",
                            alignItems: { xs: "center", md: "start" },
                          }}
                        >
                          <TextField
                            disabled={fieldState != fieldToBeShown.NONE}
                            name="email"
                            label="Enter your email address"
                            variant="standard"
                            sx={{
                              ":focus": {
                                color: colors.black,
                              },
                              "& .MuiOutlinedInput-input:focus": {
                                color: colors.black,
                              },

                              width: "100%",
                            }}
                            inputProps={{
                              style: {
                                WebkitBoxShadow: `0 0 0 1000px ${colors.paperGray} inset`,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: colors.black,
                                fontSize: 14,
                                color: colors.lightGray,
                                // WebkitBoxShadow: `0 0 0 1000px ${colors.primaryWhite} inset`,
                                zIndex: 1,
                              },
                            }}
                            InputProps={{
                              sx: { color: colors.headingGray },
                              endAdornment: (
                                <>
                                  {fieldState != fieldToBeShown.NONE && (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => {
                                          setfieldState(fieldToBeShown.NONE);
                                          setIsButtonLoading(false);
                                          setIsProcessing(false);
                                          setIsResendEnable(true);
                                          clearTime();
                                        }}
                                      >
                                        <MdEdit />
                                      </IconButton>
                                    </InputAdornment>
                                  )}
                                </>
                              ),
                            }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          />
                          <FormHelperText error>
                            {errors.email && touched.email && errors.email}
                          </FormHelperText>
                        </Box>

                        <>
                          {(fieldState == fieldToBeShown.OTP ||
                            fieldState == fieldToBeShown.VERIFYAFTEROTP ||
                            fieldState == fieldToBeShown.VERIFYSIGNUPOTP) && (
                            <Box
                              className="displayStartColumn"
                              sx={{
                                width: "100%",
                                alignItems: { xs: "start", md: "start" },
                                mt: 2,
                              }}
                            >
                              <Box
                                component={"label"}
                                sx={{
                                  color: colors.lightGray,
                                  fontSize: 12,
                                }}
                              >
                                OTP
                              </Box>
                              <OTPInput
                                name="otp"
                                value={values.otp}
                                inputVariant="standard"
                                autoComplete="off"
                                onChange={(e) => {
                                  setFieldValue("otp", e);
                                }}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                                autoFocus
                                OTPLength={6}
                                secure
                              />
                              <FormHelperText error>
                                {errors.otp && touched.otp && errors.otp}
                              </FormHelperText>
                              {(fieldState == fieldToBeShown.OTP ||
                                fieldState == fieldToBeShown.VERIFYSIGNUPOTP ||
                                fieldState ==
                                  fieldToBeShown.VERIFYAFTEROTP) && (
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  justifyContent={"flex-end"}
                                  mt={2}
                                  // mr={20}
                                >
                                  {timer == 0 ? (
                                    <>
                                      {isResendEnable && (
                                        <Typography
                                          color="secondary"
                                          variant="outlined"
                                          disabled={isLoading}
                                          sx={{ cursor: "pointer", mr: 0.6 }}
                                          onClick={() => {
                                            if (isForgotPassword) {
                                              generateOTPHandler(values);
                                            } else {
                                              resendOtp(values?.email);
                                            }
                                          }}
                                        >
                                          {isLoading ? (
                                            <CircularProgress
                                              color="info"
                                              size={"20px"}
                                              sx={{ mr: 1 }}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                          Resend OTP
                                        </Typography>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {isResendEnable && (
                                        <Typography
                                          variant="h6"
                                          sx={{ cursor: "pointer" }}
                                        >
                                          {formatTime(timer)}
                                        </Typography>
                                      )}
                                    </>
                                  )}
                                </Grid>
                              )}
                            </Box>
                          )}

                          {fieldState == fieldToBeShown.PASSWORD && (
                            <Box
                              className="displayStartColumn"
                              sx={{
                                width: "100%",
                                alignItems: { xs: "center", md: "start" },
                              }}
                            >
                              <TextField
                                type={isObsecure ? "text" : "password"}
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label="Enter Password"
                                variant="standard"
                                sx={{
                                  color: colors.black,
                                  ":focus": {
                                    color: colors.black,
                                  },
                                  "& .MuiOutlinedInput-input:focus": {
                                    color: colors.black,
                                  },
                                  "& .Mui-disabled": {
                                    backgroundColor: colors.grayBg,
                                  },
                                  width: "100%",
                                }}
                                InputLabelProps={{
                                  style: {
                                    fontSize: 14,
                                    color: colors.lightGray,
                                  },
                                }}
                                InputProps={{
                                  sx: { color: colors.headingGray },
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => {
                                          setIsObsecure((p) => !p);
                                        }}
                                      >
                                        {isObsecure ? (
                                          <AiOutlineEye
                                            color={colors.lightGray}
                                            size={"22px"}
                                          />
                                        ) : (
                                          <AiOutlineEyeInvisible
                                            color={colors.lightGray}
                                            size={"22px"}
                                          />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <FormHelperText error>
                                {errors.password &&
                                  touched.password &&
                                  errors.password}
                              </FormHelperText>
                            </Box>
                          )}
                          {fieldState == fieldToBeShown.CONFIRMPASSWORD && (
                            <>
                              <Box
                                className="displayStartColumn"
                                sx={{
                                  width: "100%",
                                  alignItems: { xs: "center", md: "start" },
                                  mt: 2,
                                }}
                              >
                                <TextField
                                  type={
                                    isPasswordObsecure ? "text" : "password"
                                  }
                                  name="password"
                                  onChange={(e) => {
                                    setPassword(e?.target?.value);
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  label="Enter Password"
                                  variant="standard"
                                  sx={{
                                    color: colors.black,
                                    ":focus": {
                                      color: colors.black,
                                    },
                                    "& .MuiOutlinedInput-input:focus": {
                                      color: colors.black,
                                    },
                                    width: "100%",
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      fontSize: 14,
                                      color: colors.lightGray,
                                    },
                                  }}
                                  InputProps={{
                                    sx: { color: colors.headingGray },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => {
                                            setIsPasswordObsecure((p) => !p);
                                          }}
                                        >
                                          {isPasswordObsecure ? (
                                            <AiOutlineEye
                                              color={colors.lightGray}
                                              size={"22px"}
                                            />
                                          ) : (
                                            <AiOutlineEyeInvisible
                                              color={colors.lightGray}
                                              size={"22px"}
                                            />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <FormHelperText error>
                                  {errors.password &&
                                    touched.password &&
                                    errors.password}
                                </FormHelperText>
                              </Box>
                              <Box
                                className="displayStartColumn"
                                sx={{
                                  width: "100%",
                                  alignItems: { xs: "center", md: "start" },
                                }}
                              >
                                <TextField
                                  type={
                                    isConfirmPasswordObsecure
                                      ? "text"
                                      : "password"
                                  }
                                  name="confirmPassword"
                                  onChange={(e) => {
                                    setConfirmPassword(e?.target?.value);
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  label="Enter Confirm Password"
                                  variant="standard"
                                  sx={{
                                    color: colors.black,
                                    ":focus": {
                                      color: colors.black,
                                    },
                                    "& .MuiOutlinedInput-input:focus": {
                                      color: colors.black,
                                    },
                                    width: "100%",
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      fontSize: 14,
                                      color: colors.lightGray,
                                    },
                                  }}
                                  InputProps={{
                                    sx: { color: colors.headingGray },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => {
                                            setIsConfirmPasswordObsecure(
                                              (p) => !p
                                            );
                                          }}
                                        >
                                          {isConfirmPasswordObsecure ? (
                                            <AiOutlineEye
                                              color={colors.lightGray}
                                              size={"22px"}
                                            />
                                          ) : (
                                            <AiOutlineEyeInvisible
                                              color={colors.lightGray}
                                              size={"22px"}
                                            />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <FormHelperText error>
                                  {errors.confirmPassword &&
                                    touched.confirmPassword &&
                                    errors.confirmPassword}
                                </FormHelperText>
                              </Box>
                              <Box
                                className="displayStartColumn"
                                sx={{
                                  width: "100%",
                                  alignItems: { xs: "center", md: "start" },
                                }}
                              ></Box>
                              {!isForgotPassword && (
                                <>
                                  <Box
                                    className="displayStartColumn"
                                    sx={{
                                      width: "100%",
                                      alignItems: { xs: "center", md: "start" },
                                    }}
                                  >
                                    <TextField
                                      type="text"
                                      name="refCode"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "refCode",
                                          e?.target?.value
                                        );
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                      label="Enter referral code"
                                      variant="standard"
                                      sx={{
                                        color: colors.black,
                                        ":focus": {
                                          color: colors.black,
                                        },
                                        "& .MuiOutlinedInput-input:focus": {
                                          color: colors.black,
                                        },
                                        width: "100%",
                                      }}
                                      InputLabelProps={{
                                        style: {
                                          fontSize: 14,
                                          color: colors.lightGray,
                                        },
                                      }}
                                    />
                                    <FormHelperText error>
                                      {errors.refCode &&
                                        touched.refCode &&
                                        errors.refCode}
                                    </FormHelperText>
                                  </Box>
                                  <Box
                                    className="displayStart"
                                    sx={{
                                      width: "100%",
                                      alignItems: { xs: "center" },
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Checkbox
                                      checked={tAndCAccepted}
                                      onChange={(e) => {
                                        setTAndCAccepted(e?.target?.checked);
                                      }}
                                      sx={{
                                        "&.MuiCheckbox-root": {
                                          pl: "0px",
                                        },
                                      }}
                                    />
                                    <Typography variant="body2">
                                      I agree to tarality's{" "}
                                      <Box
                                        component={"span"}
                                        sx={{
                                          color: colors.blueText,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setPrivacyModal(true);
                                        }}
                                      >
                                        Terms of Service
                                      </Box>
                                    </Typography>
                                  </Box>
                                </>
                              )}
                            </>
                          )}
                        </>
                      </Container>
                    </Box>
                  )}
                </Formik>
                <Container maxWidth="xl">
                  <Box
                    sx={{
                      width: "100%",
                      mt: 2,
                      display: "flex",
                      alignItems: "flex-end",
                      flexDirection: "column",
                    }}
                  >
                    {!isForgotPassword && (
                      <Typography
                        variant="body1"
                        sx={{
                          color: colors.btnBlue,
                          fontSize: 14,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setfieldState(fieldToBeShown.NONE);
                          setIsForgotPassword(true);
                        }}
                      >
                        Forgot Password?
                      </Typography>
                    )}
                    <Button
                      variant="text"
                      fullWidth
                      sx={{
                        mt: 2,
                        "&.Mui-disabled": {
                          background: `${colors.blueText} !important`,
                          opacity: "0.7",
                        },
                      }}
                      style={{
                        background: colors.btnBlue,
                        color: colors.primaryWhite,
                      }}
                      onClick={() => {
                        ref.current?.handleSubmit();
                      }}
                      disabled={
                        isButtonLoading ||
                        (!isForgotPassword &&
                          fieldState == fieldToBeShown.CONFIRMPASSWORD &&
                          !tAndCAccepted)
                      }
                    >
                      {" "}
                      Submit{" "}
                      {isButtonLoading && (
                        <Box sx={{ ml: 1 }}>
                          {" "}
                          <CircularProgress size={"12px"} />
                        </Box>
                      )}
                    </Button>
                  </Box>
                </Container>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {privacyModal && (
        <PrivacyModal
          open={privacyModal}
          setOpen={setPrivacyModal}
          openFrom={"SIGNUP"}
        />
      )}
    </div>
  );
};

export default LoginRegisterModal;

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
}
