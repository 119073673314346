import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import FlexButton from "./FlexButton";
import { useNavigate } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { colors } from "../assets/colors";
export default function PageTitle({
  rightBtnTitle,
  rightBtnHref,
  title,
  isRightButtonNotVisible,
}) {
  const navigate = useNavigate();
  return (
    <Box className="displaySpacebetween">
      <Box className="displayStart">
        {isRightButtonNotVisible ? (
          <></>
        ) : (
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon
              sx={{
                fontSize: {
                  lg: "22px",
                  md: "22px",
                  sm: "22px",
                  xs: "18px",
                  color: colors.cardBackground,
                },
              }}
            />
          </IconButton>
        )}
        <Typography
          variant="h3"
          sx={{
            fontSize: { lg: "24px", md: "24px", sm: "24px", xs: "18px" },
          }}
        >
          {title}
        </Typography>
      </Box>
      {rightBtnTitle && (
        <Box>
          <FlexButton
            title={rightBtnTitle}
            color="primary"
            onClick={() => navigate(rightBtnHref)}
            sx={{ padding: { lg: "10px", sm: "10px", md: "10px", xs: "4px" } }}
          />
        </Box>
      )}
    </Box>
  );
}
