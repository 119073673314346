import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Drawer, Hidden, List, ListSubheader } from "@mui/material";
import { styled } from "@mui/system";
import ThemeContext from "../../../context/themeContext";
import { colors } from "../../../assets/colors";
import { useLocation } from "react-router";
import NavItem from "../../DashboardLayout/NavBar/NavItem";
import RightOptions from "../../DashboardLayout/TopBar/RightOptions";
// import { FlexLayerContext } from "../../../context/flexLayer";
const MobileDrawer = styled(Drawer)(({ theme }) => ({
  width: 272,
  "& .MuiPaper-root": {
    borderRadius: "5px",
    //   "linear-gradient(101deg, #a7aaf1 -2.08%, #3147c5fc 52.7%, #04042d 104.17%) !important",
  },
}));
const SideMenuBox = styled(Box)(({ theme }) => ({
  width: "200px",
  "& .MuiCollapse-wrapperInner": {
    marginLeft: "20px",
  },
}));

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    // const open = matchPath(pathname, {
    //   path: item.href,
    //   exact: true,
    // });
    const open = pathname === item.href;
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        type="mobile"
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        type="mobile"
      />
    );
  }

  return acc;
}

const sections = [
  // {
  //   items: [
  //     {
  //       title: "Buy Crypto",
  //       href: "/buy-crypto-fast-trading",
  //       icon: "",
  //     },
  //     {
  //       title: "BNPL",
  //       href: "/product-bnpl",
  //       icon: "",
  //     },
  //     {
  //       title: "Borrow",
  //       href: "/borrow",
  //       icon: "",
  //     },
  //     {
  //       title: "Fixed Deposit",
  //       href: "/fixed-deposit",
  //       icon: "",
  //     },
  //     {
  //       title: "Referral",
  //       href: "/referrals",
  //       icon: "",
  //     },
  //     {
  //       title: "Reward",
  //       href: "/rewards",
  //       icon: "",
  //     },
  //   ],
  // },
];

const NavBarRight = ({ onMobileClose, openMobile }) => {
  const route = useLocation();
  const themeSettings = useContext(ThemeContext);
  // const { selectedCurrency, coinList } = useContext(FlexLayerContext);
  // const pair =
  //   coinList &&
  //   coinList[0] &&
  //   selectedCurrency &&
  //   `${coinList[0]?.symbol}/${selectedCurrency?.toUpperCase()}`;
  // console.log("pair----", pair);
  const themeColors = {
    backgroundColor: "transparent",
    color: colors.primaryWhite,
    boxShadow: "none",
  };
  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      sx={themeColors}
    >
      <Box pt={2} pb={2}>
        <SideMenuBox>
          {sections?.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                img: section.img,
                items: section.items,
                pathname: route.pathname,
                state: section.tabview,
              })}
            </List>
          ))}
        </SideMenuBox>
        <RightOptions
          themeSettings={themeSettings}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        />
      </Box>

      {/* Rest of your code */}
    </Box>
  );

  return (
    <Hidden lgUp>
      <MobileDrawer
        anchor="right"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        <Box
          p={2}
          sx={{
            background: "transparent !important",
          }}
        >
          {content}
        </Box>
      </MobileDrawer>
    </Hidden>
  );
};

NavBarRight.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBarRight;
