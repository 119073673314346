import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  DialogActions,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { colors } from "../../assets/colors";

export default function ImagePreviewModal({ open, close, img, setFieldValue }) {
  return (
    <Dialog
      open={open}
      // onClose={close}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle position="relative">
        <Typography variant="h4" textAlign="center"></Typography>
        <Box sx={{ position: "absolute", top: "8px", right: "8px" }}>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box mt={2}>
          <img src={img} alt="" width={"100%"} />
        </Box>
        <Grid container sx={{ justifyContent: "center", mt: 4, mb: 2, gap: 2 }}>
          <Button
            variant="text"
            style={{
              background: colors.green,
              color: colors.primaryWhite,
            }}
            onClick={() => {
              close();
            }}
          >
            Confirm
          </Button>
          <Button
            variant="text"
            style={{
              background: colors.red,
              color: colors.primaryWhite,
            }}
            onClick={() => {
              setFieldValue("imageURL", "");
              close();
            }}
          >
            Cancel
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
