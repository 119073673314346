import {
  Box,
  Card,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import SupportMessage from "./SupportMessage";
import UserMessage from "./UserMessage";
import SendIcon from "@mui/icons-material/Send";
import { colors } from "../../../assets/colors";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SupportOptions from "./SupportOptions";
import MessageModal from "../../../components/modals/MessageModal";
import ErrorModal from "../../../components/modals/ErrorModal";
import { apiRouterCall } from "../../../api/services";
import ScreenLoader from "../../../components/screenLoader";
import { AuthContext } from "../../../context/auth";
import ScrollToBottom, { useScrollToBottom } from "react-scroll-to-bottom";
import { css } from "@emotion/css";
import toast from "react-hot-toast";
import FeedbackModal from "../../../components/modals/FeedbackModal";
import SuccessModal from "../../../components/modals/SuccessModal";

export default function MessageChatSection({
  selectedHead,
  isNew,
  callback,
  setSelectedHead,
  setIsNew,
}) {
  const auth = useContext(AuthContext);
  const { profile } = auth.userData;
  const [messageData, setMessageData] = useState({
    message: "",
    subject: "",
    image: "",
    blob: "",
  });
  const [isError, setIsError] = useState(false);
  const [isUnSupported, setIsUnSupported] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [chatData, setChatData] = useState({});
  const [openFeedback, setOpenFeedback] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const scrollToBottom = useScrollToBottom();

  const clear = () => {
    setMessageData({
      message: "",
      subject: "",
      blob: "",
      image: "",
    });
  };
  const ROOT_CSS = css({
    height: "calc(100dvh - 480px)",
    marginTop: "10px",
    borderRadius: "8px",
  });

  //function to track drag in
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  //function to track drag out
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  //function to track handle over
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  //function to upload the dragged file
  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const droppedFile = e.dataTransfer.files[0];
    const isValid = validateAndSetFile(droppedFile);
    if (!isValid) {
      setIsUnSupported(true);
      return;
    }
    setMessageData({
      ...messageData,
      blob: URL.createObjectURL(droppedFile),
      image: droppedFile,
    });
  };

  //function to validate the file type
  const validateAndSetFile = (selectedFile) => {
    if (selectedFile) {
      const allowedTypes = ["image/png", "image/jpeg"];
      if (allowedTypes.includes(selectedFile.type)) {
        return true;
      } else {
        return false;
      }
    }
  };

  //function to create a new support query
  const raiseNewTicketHandler = async () => {
    try {
      if (
        !messageData.subject ||
        !messageData.message?.trim() ||
        messageData.message?.trim() === ""
      ) {
        setIsError(true);
        return;
      }

      setIsProcessing(true);
      let image;
      if (messageData.image) {
        image = await uploadFileHandler(messageData.image);
      }
      const response = await apiRouterCall({
        method: "POST",
        endPoint: "raiseTicket",
        data: {
          categoryId: messageData.subject,
          message: messageData.message,
          image: image ? image : "",
        },
      });
      if (response.data.responseCode === 200) {
        setIsNew(false);
        clear();
        setSelectedHead(response.data.result._id);
        callback();
      }
      setIsProcessing(false);
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };

  //function to send a new message to a existing query
  const sendMessageHandler = async () => {
    try {
      if (!messageData.message?.trim() || messageData.message?.trim() === "") {
        setIsError(true);
        return;
      }
      setIsProcessing(true);
      let image;
      if (messageData.image) {
        image = await uploadFileHandler(messageData.image);
      }
      const response = await apiRouterCall({
        method: "POST",
        endPoint: "reply",
        data: {
          ticketId: selectedHead,
          message: messageData.message,
          image: image ? image : "",
        },
      });
      if (response.data.responseCode === 200) {
        clear();
        getPreviousDataHandler(false);
      }
      setIsProcessing(false);
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };

  //function to upload the image
  const uploadFileHandler = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await apiRouterCall({
        method: "POST",
        endPoint: "uploadImageFile",
        data: formData,
      });
      if (response.data.responseCode === 200) {
        return response?.data?.result?.path;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.responseMessage);
      return false;
    }
  };
  //function to get the previous query conversations data
  const getPreviousDataHandler = async (state) => {
    try {
      if (state) {
        setChatData([]);
      }
      setIsProcessing(state);
      const response = await apiRouterCall({
        method: "GET",
        endPoint: "ticketDetails",
        id: selectedHead,
      });
      if (response.data.responseCode === 200) {
        setChatData(response.data.result);
        scrollToBottom();
      }
      setIsProcessing(false);
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };

  //function to store the user feedback
  const saveUserFeedbackHandler = async (rating, message) => {
    try {
      setOpenFeedback(false);
      setIsProcessing(true);
      const response = await apiRouterCall({
        method: "POST",
        endPoint: "submitFeedback",
        data: {
          ticketId: chatData?._id,
          feedbackMessage: message,
          starRate: rating,
        },
      });
      if (response.data.responseCode === 200) {
        setIsSuccess(true);
        getPreviousDataHandler(false);
      }
      setIsProcessing(false);
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (selectedHead) {
      getPreviousDataHandler(true);
    }
  }, [selectedHead]);

  useEffect(() => {
    if (
      chatData &&
      chatData?.ticketStatus === "resolved" &&
      !chatData?.isFeedback
    ) {
      setOpenFeedback(true);
    }
  }, [chatData]);
  return (
    <Fragment>
      <Card
        sx={{
          padding: "10px",
        }}
      >
        <Paper
          sx={{
            padding: "10px",
            "&.MuiPaper-root": {
              background: "transparent !important",
            },
          }}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {isNew && (
            <SupportOptions
              value={messageData.subject}
              onClick={(data) =>
                setMessageData({
                  ...messageData,
                  subject: data,
                  blob: "",
                  image: "",
                })
              }
            />
          )}
          {/* <div className="chat-container" ref={chatContainerRef}> */}
          <ScrollToBottom
            className={ROOT_CSS}
            mode="bottom"
            scrollToBottom={scrollToBottom}
          >
            <Box>
              {!isNew &&
                chatData &&
                chatData?.conversations?.map((data, i) => {
                  return (
                    <Fragment key={`messages${i}`}>
                      {data.system_message ? (
                        <SupportMessage data={data} />
                      ) : (
                        <UserMessage profile={profile} data={data} />
                      )}
                    </Fragment>
                  );
                })}
            </Box>
          </ScrollToBottom>
          {/* </div> */}
        </Paper>
        {((chatData && chatData.ticketStatus !== "resolved") || isNew) && (
          <Box className="displayCenter" mt={1}>
            <TextField
              autoComplete="off"
              fullWidth
              multiline
              placeholder="Enter your message..."
              value={messageData.message}
              onChange={(e) =>
                setMessageData({ ...messageData, message: e.target.value })
              }
              sx={{
                "& .MuiOutlinedInput-root": {
                  background: `${colors.primaryWhite} !important`,
                },
              }}
            />
            <UploadAttachmentButton
              onFileChange={(file) =>
                setMessageData({
                  ...messageData,
                  blob: URL.createObjectURL(file),
                  image: file,
                })
              }
            />
            <IconButton
              sx={{
                backgroundColor: colors.btnGold,
                "&:hover": {
                  backgroundColor: colors.btnGold,
                },
              }}
              onClick={() => {
                if (isNew) {
                  raiseNewTicketHandler();
                } else {
                  sendMessageHandler();
                }
              }}
            >
              <SendIcon sx={{ color: colors.white }} />
            </IconButton>
          </Box>
        )}
        {!isNew && chatData && chatData.ticketStatus === "resolved" && (
          <Typography
            variant="body2"
            fontWeight={600}
            mt={1}
            textAlign={"center"}
          >
            This ticket has been marked as resolved by team!
          </Typography>
        )}
      </Card>
      {messageData && messageData.blob !== "" && (
        <MessageModal
          open={true}
          close={() => setMessageData({ ...messageData, blob: "", image: "" })}
          messageData={messageData}
          setMessageData={setMessageData}
          onClick={() => {
            if (isNew) {
              raiseNewTicketHandler();
            } else {
              sendMessageHandler();
            }
          }}
        />
      )}
      {isUnSupported && (
        <ErrorModal
          title={"Unsupported Format!"}
          description={`Please select only .png,.jpeg,.webp,.jpg files to upload`}
          open={isUnSupported}
          close={() => setIsUnSupported(false)}
        />
      )}
      {isProcessing && (
        <ScreenLoader
          open={isProcessing}
          close={() => setIsProcessing(false)}
        />
      )}
      {isError && (
        <ErrorModal
          title={!messageData.subject ? "No message! :(" : "No message!"}
          description={
            !messageData.subject
              ? "Ohoo! You forget to write a message."
              : "Ohoo! You forget to write a message."
          }
          open={isError}
          close={() => setIsError(false)}
        />
      )}

      {openFeedback && (
        <FeedbackModal
          open={openFeedback}
          close={() => setOpenFeedback(false)}
          onClick={(v1, v2) => saveUserFeedbackHandler(v1, v2)}
        />
      )}
      {isSuccess && (
        <SuccessModal
          open={isSuccess}
          close={() => setIsSuccess(false)}
          title={"Thank you!!"}
          description={`We appreciate your efforts to take the time to write us! Feel free to connect again.`}
        />
      )}
    </Fragment>
  );
}

export const UploadAttachmentButton = ({ onFileChange }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    onFileChange(file);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <IconButton onClick={handleButtonClick}>
      <>
        <input
          ref={fileInputRef}
          style={{ display: "none" }}
          id="raised-button-file"
          //   multiple
          //   max={5}
          accept=".png,.jpeg,.webp,.jpg"
          type="file"
          onChange={handleFileChange}
        />
      </>
      <AddCircleOutlineIcon sx={{ color: colors.white }} />
    </IconButton>
  );
};
