import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Toolbar,
  AppBar,
  Button,
  Box,
  Menu,
  MenuItem,
  Container,
  Input,
  InputAdornment,
  Grid,
} from "@mui/material";
import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import Logo from "../../../components/Logo";
import { useLocation, useNavigate } from "react-router";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeContext from "../../../context/themeContext";
import { colors } from "../../../assets/colors";
import NavBarRight from "../../HomeLayout/NavBarRight";
import RightOptions from "./RightOption";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { GoSearch } from "react-icons/go";
import { AuthContext } from "../../../context/auth";
import FlexButton from "../../../components/FlexButton";
import SupportButton from "../../../components/SupportButton";

// import { AuthContext } from "../../../context/auth";
const TopBarNew = ({ onMobileNavOpen, isDashboard, open, setOpen }) => {
  const navigate = useNavigate();
  const { userLoggedIn } = useContext(AuthContext);
  const navItems = [
    {
      name: "Buy Crypto",
      href: "/buy-crypto-fast-trading",
      isMenu: false,
    },

    {
      name: "Borrow",
      href: "/borrow",
      isMenu: false,
    },

    {
      name: "BNPL",
      href: "/product-bnpl",
      isMenu: false,
    },

    {
      name: "Fixed Deposit",
      href: "/fixed-deposit",
      isMenu: false,
    },
    // {
    //   name: "More",
    //   href: "/",
    //   isMenu: true,

    //   menuItems: [
    //     {
    //       title: "Referral",
    //       icon: "/assets/icons/topbar/gift.png",
    //       href: "/referrals",
    //     },
    //     {
    //       title: "Reward",
    //       icon: "/assets/icons/topbar/network-more.png",
    //       href: "/rewards",
    //     },
    //   ],
    // },
    {
      name: "Referral",
      href: "/referrals",
      isMenu: false,
    },
    {
      name: "Reward",
      href: "/rewards",
      isMenu: false,
    },
  ];
  const [openDrawer, setOpenDrawer] = useState(false);
  const themeSettings = useContext(ThemeContext);
  const themeColors = {
    // backgroundColor:
    //   // themeSettings.settings.theme === "DARK" ? colors.bgDark : colors.white,
    //   `${colors.cardBackground} !important`,
    // background:
    //   "linear-gradient(101deg, #04042d -2.08%, #3147c5fc 52.7%, #a7aaf1 104.17%) !important",
    color: colors.darkPrimary,
    boxShadow: "none",
    borderRadius: "0px",
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar component="nav" sx={themeColors}>
        {/* <Container maxWidth={userLoggedIn ? "xl" : "lg"}> */}
        <Container maxWidth={"xl"}>
          <Toolbar>
            {isDashboard && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={onMobileNavOpen}
                sx={{
                  mr: 2,
                  display: { md: "none" },
                  color: colors.headingGray,
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Logo
              style={{
                width: "60%",
                minWidth: "100px",
                maxWidth: "150px",
                cursor: "pointer",
              }}
              sx={{
                position: "relative",
                left: { xs: "-30px", md: "-40px" },
              }}
              onClick={() => {
                if (userLoggedIn) {
                  navigate("/dashboard");
                } else {
                  navigate("/");
                }
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                py: 2,
              }}
            >
              <Box width="100%" sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    display: { xs: "none", sm: "none", lg: "flex" },
                    // marginLeft: "310px",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  {/* {navItems &&
                    navItems.map((item, i) => {
                      return (
                        <DrawerTopButton
                          item={item}
                          key={`navBtn${i}`}
                          navigate={navigate}
                        />
                      );
                    })} */}
                </Box>
              </Box>

              <RightOptions
                themeSettings={themeSettings}
                sx={{
                  display: { lg: "flex", sm: "none", md: "none", xs: "none" },
                  alignItems: "center",
                  // mr: 6,
                }}
                open={open}
                setOpen={setOpen}
              />
              {!userLoggedIn && (
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    sx={{
                      py: 1,
                      background: colors.blueText,
                      color: colors.primaryWhite,
                      borderRadius: 10,
                      mr: 2,
                      ":hover": {
                        background: colors.primaryWhite,
                        color: colors.blueText,
                      },
                    }}
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Login/Register
                  </Button>
                  {/* <FlexButton
                    title="Login/Register"
                    color="primary"
                    disabled={false}
                    fullWidth={false}
                    // onClick={() => navigate("/signup")}
                    sx={{
                      py: 1,
                    }}
                    onClick={() => {
                      setOpen(true);
                    }}
                  /> */}
                </Grid>
              )}
              {!userLoggedIn && (
                <SupportButton sx={{ color: colors.blueText }} />
              )}

              {userLoggedIn && (
                <>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    sx={{ display: { sm: "block", lg: "none" } }}
                    onClick={() => setOpenDrawer(true)}
                  >
                    <MenuIcon />
                  </IconButton>
                  <NavBarRight
                    onMobileClose={() => setOpenDrawer(false)}
                    openMobile={openDrawer}
                    themeSettings={themeSettings}
                  />
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

const DrawerTopButton = ({ navigate, item }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const pathName = useLocation().pathname;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      {item?.isLogin ? (
        <Button
          color="secondary"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            if (item.isMenu) {
              handleClick(e);
            } else {
              navigate(item.href);
            }
          }}
          sx={{
            textTransform: "none",
            margin: "0px 4px",
          }}
          endIcon={
            item.isMenu && (
              <>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} </>
            )
          }
        >
          {item.name}
        </Button>
      ) : (
        <Button
          color="secondary"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            if (item.isMenu) {
              handleClick(e);
            } else {
              navigate(item.href);
            }
          }}
          sx={{
            textTransform: "none",
            margin: "0px 4px",
            color: colors.primaryWhite,
            ":hover": {
              color: colors.primaryWhite,
            },
          }}
          endIcon={
            item.isMenu && (
              <>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} </>
            )
          }
        >
          {item.name}
        </Button>
      )}

      {open && (
        <>
          {item.isMenu && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {item &&
                item.menuItems &&
                item.menuItems.map((x, i) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate(x.href);
                      }}
                      key={`topMenuSub${i}`}
                    >
                      <img src={x.icon} alt="" />
                      &nbsp;&nbsp;{x.title}
                    </MenuItem>
                  );
                })}
            </Menu>
          )}
        </>
      )}
    </React.Fragment>
  );
};

TopBarNew.propTypes = {
  className: PropTypes.string,
};
TopBarNew.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBarNew;
