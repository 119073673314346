import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../../assets/colors";
import { rightSide } from "../../../components/modals/SupportModal";

export default function ChatHead({
  selectedHead,
  setSelectedHead,
  data,
  setIsNew,
  setRightSideState,
}) {
  return (
    <Paper
      sx={{
        padding: "10px",
        marginTop: "5px",
        cursor: "pointer",
        border:
          selectedHead === data._id
            ? `1px solid ${colors.btnGold}`
            : "1px solid transparent",
        "&:hover": {
          border: `1px solid ${colors.btnGold}`,
        },
      }}
      onClick={() => {
        setIsNew(false);
        setSelectedHead(data._id);
        setRightSideState && setRightSideState(rightSide.CHAT);
      }}
    >
      <Box className="displayStart">
        <Typography variant="subtitle1" fontWeight={600}>
          {data?.ticketId}
        </Typography>
        &nbsp;&nbsp;
        <Typography variant="subtitle1">{data?.message}</Typography>
      </Box>
    </Paper>
  );
}
