import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import FlexButton from "../FlexButton";

export default function SupportFilter({
  close,
  open,
  onClick,
  selectedFilter,
}) {
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle position="relative">
        <Typography variant="h4" textAlign="center">
          Select{" "}
        </Typography>
        <Box sx={{ position: "absolute", top: "8px", right: "8px" }}>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <FlexButton
          fullWidth={true}
          color={selectedFilter === "pending" ? "secondary" : "primary"}
          title="Pending"
          sx={{ marginBottom: "10px" }}
          onClick={() => onClick("pending")}
        />
        <FlexButton
          fullWidth={true}
          color={selectedFilter === "in-process" ? "secondary" : "primary"}
          title="In Process"
          sx={{ marginBottom: "10px" }}
          onClick={() => onClick("in-process")}
        />
        <FlexButton
          fullWidth={true}
          color={selectedFilter === "resolved" ? "secondary" : "primary"}
          title="Resolved"
          onClick={() => onClick("resolved")}
        />
      </DialogContent>
    </Dialog>
  );
}
