import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Rating } from "react-simple-star-rating";
import FlexButton from "../FlexButton";

export default function FeedbackModal({ open, close, onClick }) {
  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    // other logic
  };
  // Optinal callback functions
  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => console.log(value, index);

  //function to save the feedback

  return (
    <Dialog
      open={open}
      //  onClose={close}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle position="relative">
        <Typography variant="h4" textAlign="center">
          Please give your feedback!
        </Typography>
        <Box sx={{ position: "absolute", top: "8px", right: "8px" }}>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ paddingBottom: "30px" }}>
        <Box className="displayCenter" mt={1}>
          <Rating
            onClick={handleRating}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
            onPointerMove={onPointerMove}
            /* Available Props */
          />
        </Box>

        {isSubmit && rating <= 0 && (
          <Box className="displayCenter" mt={-1} mb={1}>
            <FormHelperText error>Please rate us first.</FormHelperText>
          </Box>
        )}

        <Box>
          <TextField
            autoComplete="off"
            placeholder="Please write a feedback for us..."
            multiline
            rows={4}
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <FlexButton
            fullWidth={true}
            color="primary"
            title="Submit"
            onClick={() => {
              setIsSubmit(true);
              if (rating > 0) {
                setIsSubmit(false);
                onClick(rating, message);
              }
            }}
            sx={{ marginTop: "10px" }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
