import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../../assets/colors";
export default function SuccessModal({ open, close, title, description }) {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        close();
      }, 20000);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="xs">
      <Box className="gradientBox">
        <DialogTitle position="relative" sx={{ bgcolor: colors.green }}>
          <Typography
            variant="h4"
            textAlign="center"
            sx={{ color: colors.primaryWhite }}
          >
            {title}
          </Typography>
          <Box
            sx={{ position: "absolute", top: "8px", right: "8px", zIndex: 9 }}
          >
            <IconButton onClick={close} sx={{ color: colors.primaryWhite }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box align="center" style={{ padding: "25px 0px 0px" }}>
            <Box>
              {/* <img src="/assets/images/status.gif" alt="" /> */}
              <img src="/assets/logo/done.gif" alt="" />
            </Box>
            <Box mb={3} mt={2}>
              <Typography
                style={{ fontWeight: "600", color: colors.headingGray }}
                variant="h5"
              >
                {description}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
