import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress, IconButton } from "@mui/material";
import { colors } from "../assets/colors";
import CloseIcon from "@mui/icons-material/Close";
import BorrowPolicy from "../views/pages/borrowpolicy/BorrowPolicy";
import BNPLPolicy from "../views/pages/bnplpolicy/BNPLPolicy";
import FixedPolicy from "../views/pages/fixedpolicy";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  width: { xs: "95vw", sm: "600px", md: "800px" },
};

const PrivacyModal = ({ setOpen, open, openFrom }) => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("SubTitle");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setData();
  }, [openFrom]);

  const setData = async () => {
    switch (openFrom) {
      case "BNPL":
        setSubTitle(
          <>
            <BNPLPolicy open={true} />
          </>
        );
        break;
      case "FIXED":
        setSubTitle(
          <>
            <FixedPolicy open={true} />
          </>
        );
        break;
      case "BORROW":
        setSubTitle(
          <>
            <BorrowPolicy open={true} />
          </>
        );
        break;
      case "WALLET":
        setTitle("Privacy Notice For Tarality Wallet");
        setSubTitle(
          "Your privacy is our priority at Tarality Wallet. We take great care in handling your personal information to ensure its security and confidentiality. We collect only the essential data required for wallet functionality, and we never share your information with third parties for marketing purposes. Your data is encrypted and protected using industry-standard security measures. Trust in Tarality Wallet for a secure and private experience."
        );
        break;
      case "SIGNUP":
        setTitle("Tarality Signup Policy");
        setSubTitle(
          "By signing up for Tarality, you agree to provide accurate and current information. We use this information to deliver our services effectively and securely. Your data is protected and encrypted, and we do not share it with third parties for marketing purposes. You are responsible for maintaining the confidentiality of your account credentials. Tarality reserves the right to terminate or suspend accounts found to violate our terms of service or engage in fraudulent activities. Thank you for entrusting us with your information."
        );

        break;
      default:
        break;
    }
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {isLoading ? (
          <Box className="displayCenter">
            {" "}
            <CircularProgress sx={{ color: colors.cardBackground }} />
          </Box>
        ) : (
          <Box sx={{ position: "relative", overflow: "hidden" }}>
            <Box
              sx={{
                bgcolor: colors.blueText,
                p: 2,
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <IconButton
                sx={{
                  color: colors.primaryWhite,
                  position: "absolute",
                  right: "6px",
                  top: "9px",
                }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ color: colors.primaryWhite }}
              >
                {title}
              </Typography>
            </Box>
            <Typography
              color={colors.darkGrey}
              sx={{ mt: 2, height: "50vh", overflowY: "auto", p: 4 }}
            >
              {subTitle}
            </Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default PrivacyModal;
