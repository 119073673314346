import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  DialogActions,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { colors } from "../../assets/colors";

export default function MessageModal({
  open,
  close,
  setMessageData,
  messageData,
  onClick,
}) {
  return (
    <Dialog
      open={open}
      // onClose={close}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle position="relative">
        <Typography variant="h4" textAlign="center"></Typography>
        <Box sx={{ position: "absolute", top: "8px", right: "8px" }}>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box mt={2}>
          <img src={messageData?.blob} alt="" width={"100%"} />
        </Box>
        <Box className="displayCenter" width="100%" mt={2}>
          <TextField
            autoComplete="off"
            fullWidth
            placeholder="Enter your message..."
            value={messageData.message}
            onChange={(e) =>
              setMessageData({ ...messageData, message: e.target.value })
            }
            sx={{ marginRight: "10px" }}
          />
          <IconButton
            sx={{
              backgroundColor: colors.btnGold,
              "&:hover": {
                backgroundColor: colors.btnGold,
              },
            }}
            onClick={onClick}
          >
            <SendIcon sx={{ color: colors.white }} />
          </IconButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
