import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";
import FlexButton from "../FlexButton";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../../assets/colors";

export default function ConfirmationModal({
  close,
  title,
  open,
  description,
  onClick,
}) {
  return (
    <Dialog
      open={open}
      // onClose={close}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        position="relative"
        sx={{ bgcolor: colors.blueText, height: 50 }}
      >
        <Typography
          variant="h4"
          textAlign="center"
          sx={{ fontSize: 18, color: colors.primaryWhite }}
        >
          {title}
        </Typography>
        <Box sx={{ position: "absolute", top: "8px", right: "8px" }}>
          <IconButton onClick={close} sx={{ color: colors.primaryWhite }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Typography variant="h6" textAlign="center" sx={{ mt: 4 }}>
          {description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box className="displayCenter" width="100%">
          {console.log(title, "asgdasd")}
          {title == "Logout!" && (
            <>
              <Button
                sx={{
                  backgroundColor: colors.primaryWhite,
                  color: colors.darkGrey,
                  border: `1px solid ${colors.green}`,
                  px: 2,
                  ":hover": {
                    backgroundColor: colors.green,
                    color: colors.primaryWhite,
                  },
                  marginRight: "10px",
                  padding: "12px",
                }}
                onClick={close}
              >
                No
              </Button>
              <Button
                sx={{
                  backgroundColor: colors.primaryWhite,
                  color: colors.darkGrey,
                  border: `1px solid ${colors.red}`,
                  px: 2,
                  ":hover": {
                    backgroundColor: colors.red,
                    color: colors.primaryWhite,
                  },
                  marginRight: "10px",
                  padding: "12px",
                }}
                onClick={onClick}
              >
                Yes
              </Button>
            </>
          )}

          {title != "Logout!" && (
            <>
              <Button
                sx={{
                  backgroundColor: colors.primaryWhite,
                  color: colors.darkGrey,
                  border: `1px solid ${colors.red}`,
                  px: 2,
                  ":hover": {
                    backgroundColor: colors.red,
                    color: colors.primaryWhite,
                  },
                  marginRight: "10px",
                  padding: "12px",
                }}
                onClick={close}
              >
                No
              </Button>
              <Button
                sx={{
                  backgroundColor: colors.primaryWhite,
                  color: colors.darkGrey,
                  border: `1px solid ${colors.green}`,
                  px: 2,
                  ":hover": {
                    backgroundColor: colors.green,
                    color: colors.primaryWhite,
                  },
                  marginRight: "10px",
                  padding: "12px",
                }}
                onClick={onClick}
              >
                Yes
              </Button>
              {/* <FlexButton
                title="No"
                color="secondary"
                type=""
                sx={{ marginRight: "10px" }}
                onClick={close}
              />

              <FlexButton
                title="Yes"
                color="primary"
                type=""
                onClick={onClick}
              /> */}
            </>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}
