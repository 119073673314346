import { Avatar, Box, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../../assets/colors";
import moment from "moment";
import { getRandomAvtar } from "../../../utils";
import { img_base_url } from "../../../contants";
import PreviewModal from "../../../components/modals/PreviewModal";

export default function SupportMessage({ data }) {
  const [open, setOpen] = useState(false);
  return (
    <Box className="displayStart" height={"100%"} mt={1}>
      <Grid container spacing={0}>
        <Grid
          item
          lg={1}
          sm={1}
          md={1}
          xs={1}
          display={"flex"}
          alignItems={"end"}
          justifyContent={"center"}
        >
          {" "}
          <Box sx={{ height: "inherit" }}>
            <Avatar
              sx={{
                height: "25px",
                width: "25px",
              }}
              src={getRandomAvtar("support")}
            />
          </Box>
        </Grid>
        <Grid item lg={7} sm={7} md={7} xs={7}>
          <Card sx={{ padding: "10px", borderRadius: "10px 10px 10px 0px" }}>
            <Box
              display={"flex"}
              width={"100%"}
              justifyContent={"space-between"}
            >
              <Box>
                <Typography
                  variant="body2"
                  fontWeight={600}
                  sx={{ color: colors.btnGold }}
                >
                  Support
                </Typography>
                <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                  {data?.message}
                </Typography>
                <Box>
                  {data && data.image && (
                    <img
                      src={`${img_base_url}/${data.image}`}
                      alt=""
                      style={{
                        width: "100%",
                        borderRadius: "6px",
                        maxWidth: "200px",
                      }}
                      onClick={() => setOpen(true)}
                    />
                  )}
                </Box>
              </Box>
              <Box display={"flex"} alignItems={"end"} ml={1} minWidth={"52px"}>
                <Typography variant="body1">
                  {moment(data?.time).format("LT")}
                </Typography>
              </Box>
            </Box>
            {open && (
              <PreviewModal
                open={open}
                close={() => setOpen(false)}
                url={`${img_base_url}/${data.image}`}
                img={true}
              />
            )}
          </Card>
        </Grid>
        <Grid item lg={4} sm={4} md={4} xs={4}></Grid>
      </Grid>
    </Box>
  );
}
