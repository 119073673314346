import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function ErrorModal({ open, close, title, description }) {
  return (
    <Dialog
      open={open}
      //  onClose={close}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle position="relative">
        <Typography variant="h3" textAlign="center">
          {title}
        </Typography>
        <Box sx={{ position: "absolute", top: "8px", right: "8px" }}>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ paddingBottom: "30px" }}>
        <Box className="displayCenter" mt={1}>
          <img src="/assets/images/alert@3x.png" alt="" width="140px" />
        </Box>
        <Typography variant="h6" textAlign="center" mt={1}>
          {description}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
