import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// import Button from "@mui/material/Button";
// import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import Draggable from "react-draggable";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Box, Hidden, IconButton, Tooltip, Typography } from "@mui/material";
import { AuthContext } from "../context/auth";
import { useNavigate } from "react-router";
import { colors } from "../assets/colors";
import SupportModal from "./modals/SupportModal";
import { BiSupport } from "react-icons/bi";

const FloatingButton = styled("div")({
  position: "fixed",
  bottom: 20,
  right: 20,
  zIndex: 1000, // Ensure it's above other elements
});

export default function SupportButton({ sx }) {
  const { userLoggedIn } = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <Tooltip title="Support">
        <IconButton
          onClick={() => {
            if (userLoggedIn) {
              setOpen(true);
            } else {
              setOpen(true);
            }
          }}
          sx={{
            color: colors.primaryWhite,
            transition: "all 200ms ease-in-out 50ms",
            ":hover": {
              color: colors.cardBackground,
            },
          }}
        >
          <BiSupport style={sx} />
        </IconButton>
      </Tooltip>
      {open && <SupportModal open={open} close={() => setOpen(false)} />}
    </Fragment>
  );
}
