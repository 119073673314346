import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { Box, Container, Grid } from "@mui/material";
import { useLocation } from "react-router";
import MaintenanceMode from "../../components/MaintenanceMode";
import SupportButton from "../../components/SupportButton";
import FormCard from "../../components/FormCard";
import { colors } from "../../assets/colors";
import TopBarNew from "../HomeLayout/TopBarNew";
import { AuthContext } from "../../context/auth";
import Footer from "../HomeLayout/Footer";

const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
  position: "relative",
  // background: "#f3f5f7",
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  position: "relative",
  justifyContent: "center",
  alignItems: "flex-start",
  // minHeight: "calc(100vh - 54px)",
  minHeight: "calc(100vh - 186px)",

  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  marginTop: "54px",
}));

const ContentContainer = styled(Box)({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  justifyContent: "center",
  flexDirection: "row",
});

const Content = styled(Box)(({ theme }) => ({
  flex: "1 1 auto",
  height: "100%",
  overflow: "hidden",
  position: "relative",
  marginTop: "18px",
  // padding: "23px 25px 25px",
  backgroundColor: "orange",
  padding: "10px",
  // [theme.breakpoints.down("md")]: {
  //   padding: "23px 25px 25px",
  // },
}));

export default function DashboardLayout({ children }) {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const location = useLocation();
  const { userLoggedIn } = useContext(AuthContext);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);
  const [loginModal, setLoginModal] = useState(false);

  return (
    <React.Fragment>
      <Root>
        {/* <TopBar
          onMobileNavOpen={() => setMobileNavOpen(true)}
          isDashboard={true}
        /> */}
        <TopBarNew
          // themeSettings={themeSettings}
          onMobileNavOpen={() => setMobileNavOpen(true)}
          isDashboard={true}
          open={loginModal}
          setOpen={setLoginModal}
        />

        <Wrapper>
          {/* <ContentContainer> */}
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // height: { xs: "auto", md: "calc(100vh - 309px)" },
              mt: { xs: 2, md: 8 },
              position: "relative",
              gap: 4,
            }}
          >
            <Box
              className="displayCenter"
              sx={{ flexDirection: "row", gap: 4, alignItems: "flex-start" }}
            >
              <NavBar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
              />

              <Box
                sx={{
                  width: "100%",
                  overflowY: "auto",
                  border: { md: `1px solid ${colors.borderGray}`, xs: "none" },
                  minHeight: "72vh",
                }}
              >
                {children}
              </Box>
            </Box>
            <Footer />
          </Container>
          {/* </ContentContainer> */}
          {!userLoggedIn && <SupportButton />}
        </Wrapper>

        <MaintenanceMode />
      </Root>
    </React.Fragment>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
