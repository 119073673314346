import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function PreviewModal({ close, open, text, img, url, title }) {
  return (
    <Dialog
      open={open}
      //  onClose={close}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle position="relative">
        <Typography variant="h5" textAlign="center">
          {img ? "Preview" : title ? title : "Locking Reason"}
        </Typography>
        <Box sx={{ position: "absolute", top: "8px", right: "8px" }}>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ paddingBottom: "30px" }}>
        {img && <img src={url} alt="" width="100%" />}
        {text && (
          <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
            {text}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
}
